import {Field} from 'react-final-form';
import {MUIAutocompleteInput} from "./MUIAutocompleteInput";

const EPAutocompleteInput1 = ({items, source, className, label, disabled}) => {


    const Error = ({name}) => {
        return (<Field
            name={name}
            subscribe={{touched: true, error: true}}
            render={({meta: {touched, error}}) =>
                touched && error ? <span>{error}</span> : null}/>);
    };

    return (<div>
        <Field
            className={className}
            name={source}
            items={items}
            component={MUIAutocompleteInput}
            label={label}
            disabled={disabled}
        />
        <Error name={source}/>
    </div>);


};


export default EPAutocompleteInput1;

import * as React from 'react';
import {cloneElement, FC, useEffect, useState} from 'react';
import AddIcon from '@material-ui/icons/Add';

import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FilterProps,
    List,
    ListProps,
    ReferenceField,
    ReferenceInput,
    sanitizeListRestProps,
    SearchInput,
    TextField,
    TopToolbar,
    useGetList,
    useListContext,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteArrayInput, Title, NumberField,
} from 'react-admin';
import {Fab, IconButton, Tooltip} from '@material-ui/core';
import {Link} from "react-router-dom";
import LateOrders from "../components/LateOrders";
import TommorowsOrders from "../components/TommorowsOrders";
import TodayOrders from "../components/TodayOrders";
import InProductionOrders from "../components/InProductionOrders";

import addDays from 'date-fns/add_days';
import startOfDay from 'date-fns/start_of_day';
import endOfDay from 'date-fns/end_of_day';
import OrderShow from '../components/OrderShow';
import {useDispatch} from "react-redux";
import {closeSidebar} from './actions';
import {Styles} from "@material-ui/styles/withStyles";
import {makeStyles, Theme} from "@material-ui/core/styles";
import CardWithIcon from "../components/CardWithIcon";
import Icon from "@material-ui/icons/DirectionsRun";
import { classicNameResolver, getJSDocClassTag } from 'typescript';


interface OrderListProps extends ListProps {
    noBoxes?: boolean | false;
    addItem?: (item: any) => void;
    name?: string;
}


export const styles: Styles<Theme, any> = {
    filterLong: {
        width: '120px',
    },
    filterShort: {
        width: '80px',
    },
    topButton: {
        minWidth: '230px',
        marginRight: '30px',
        borderRadius: '5px',
    },
    priceRed: {
        color: 'red',
    },
    priceGreen: {
        color: 'green',
    }
};

const useStyles = makeStyles(styles);


const OrderFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const classes = useStyles(props);

    return (
        <Filter variant="standard" {...props} >
            <SearchInput source="q" alwaysOn className={classes.filterLong}/>
            <ReferenceInput source="customerId" reference="customers" label='Klient' alwaysOn>
                <AutocompleteInput resettable optionText="name" optionValue="id" className={classes.filterShort}/>
            </ReferenceInput>
            <DateInput source="created_gte" label="Vytvořená po" className={classes.filterLong}/>
            <DateInput source="created_lte" label="Vytvořená před" className={classes.filterLong}/>
            <ReferenceInput source="trade" reference="users" filter={{role: "Trade"}} alwaysOn>
                <AutocompleteInput resettable optionText="name" optionValue="id" className={classes.filterShort}/>
            </ReferenceInput>
            <ReferenceInput source="production" reference="users" filter={{role: "Production"}} alwaysOn>
                <AutocompleteInput resettable optionText="name" optionValue="id" className={classes.filterShort}/>
            </ReferenceInput>
            <ReferenceInput source="statusId" reference="statuses" alwaysOn label="Stav zakázky">
                <AutocompleteInput resettable optionText="name" optionValue="id" className={classes.filterShort}/>
            </ReferenceInput>
            <ReferenceArrayInput source="itemStatusIds" reference="statuses" alwaysOn label="Stav položky">
                <AutocompleteArrayInput resettable optionText="name" className={classes.filterShort}/>
            </ReferenceArrayInput>
            <ReferenceArrayInput source="supplierIds" reference="suppliers" label="Dodavatel">
                <AutocompleteArrayInput optionText="name" className={classes.filterShort}/>
            </ReferenceArrayInput>
        </Filter>
    );
};


const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>


            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};


const EmptyListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

        </TopToolbar>
    );
};


const useGetTotals = (filterValues: any, currentDate) => {


    const {total: totalLate} = useGetList(
        'commands',
        {perPage: 2, page: 1},
        {field: 'id', order: 'ASC'},
        {filter: 'late'});

    const {total: totalTomorrow} = useGetList(
        'commands',
        {perPage: 2, page: 1},
        {field: 'id', order: 'ASC'},
        {filter: 'tomorrow'});

    const {total: totalToday} = useGetList(
        'commands',
        {perPage: 2, page: 1},
        {field: 'id', order: 'ASC'},
        {filter: 'today'});

    const {total: totalRunning} = useGetList(
        'commands',
        {perPage: 2, page: 1},
        {field: 'id', order: 'ASC'},
        {filter: 'running'});

    return {
        totalLate: totalLate,
        totalTomorrow: totalTomorrow,
        totalToday: totalToday,
        totalRunning: totalRunning,
    };
};

const OrderList: FC<OrderListProps> = props => {
    const classes = useStyles(props);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closeSidebar());
    }, []);

    const [currentDate, setCurrentDate] = useState<Date>(new Date() as Date);

    const totals = useGetTotals({}, currentDate) as any;


    const getActionRowProps = (noBoxes) => {
        if (!noBoxes) {
            return {rowClick: "edit"};
        }
        return {
            expand: <OrderShow addItem={props.addItem}/>
        }
    } 

    const getStatusColor = (orderStatus) => {
        switch (orderStatus){
            case 1: // Vzorování - makety
                return 'rgba(0, 0, 255, .35)'; 
                break;
            case 2: // Příprava data klient
                return 'rgba(255, 0, 0, .35)';
                break;
            case 3: // Příprava data Gčko
                return 'rgba(0, 143, 235, .5)';
                break;
            case 4: // Ve výrobě
                return 'rgb(98, 255, 119, .5)';
                break;
            case 5: // Vyrobeno
                return 'rgba(98, 255, 119, .5)';
                break;
            case 7: // K fakturaci
                return 'rgb(0, 143, 235, .5)';
                break;
            case 8: // Nová
            default:
                return 'rgb(255, 255, 255)';
                break;
            }
    }

    const dataRowStyle = (record, index) => ({
        backgroundColor: getStatusColor(record.statusId)
    });

    function getClass(record: any){
        console.log(record);
        
    }

    const NumberFieldCompareCustom = ({record, source, compare}) => {
        console.log(record[source] + " vs " + record[compare]);
        const label = (record[source] == record[compare]) ? <>
            <NumberField
                fullWidth={true}
                source={source} record={record} locales={['cs']}
                className={classes.priceGreen}
                options={{
                    style: 'currency',
                    currency: 'CZK',
                }}/>
        </> : <>

            <NumberField
                fullWidth={true}
                source={source} record={record} locales={['cs']}
                className={classes.priceRed}
                options={{
                    style: 'currency',
                    currency: 'CZK',
                }}/>
        </>


        return record ?
            label :
            null;
    };

    return (

        <List
            {...props}
            title=" "
            sort={{field: 'created', order: 'DESC'}}
            perPage={25}
            filters={<OrderFilter/>}
            actions={!props.noBoxes ? <ListActions/> : <EmptyListActions/>}
        >
            <>
                <Title title="Seznam zakázek"/>
                {!props.noBoxes &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px'}}>
                    <div className={classes.topButton}>
                        <LateOrders value={totals.totalLate} />
                    </div>
                    <div className={classes.topButton}>
                        <TodayOrders value={totals.totalToday}/>
                    </div>
                    <div className={classes.topButton}>
                        <TommorowsOrders value={totals.totalTomorrow}/>
                    </div>
                    <div className={classes.topButton}>
                        <InProductionOrders value={totals.totalRunning}/>
                    </div>
                    <div className={classes.topButton}>
                        <CardWithIcon
                            backgroundColor="#008feb"
                            to="/commands/create"
                            icon={AddIcon}
                            title="Nová"
                        />
                    </div>
                </div>}


                <Datagrid rowStyle={dataRowStyle} optimized {...getActionRowProps(props.noBoxes)}>
                    <TextField source="orderNumber"/>
                    <TextField source="name"/>
                    <ReferenceField
                        source="statusId"
                        reference="statuses"
                        link={false}
                        label="resources.commands.fields.statusId"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <NumberField 
                        source="sellPrice" label="Prodejní cena celkem"
                        className={''}
                        locales={['cs']}
                        options={{
                            style: 'currency',
                            currency: 'CZK',
                    }}/>
                    {/* <NumberFieldCompareCustom  source="itemSellPrice" compare="sellPrice"/> */}

                    <ReferenceField
                        source="customerId"
                        reference="customers"
                        link={false}
                        label="resources.commands.fields.customer"

                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        source="production"
                        reference="users"
                        link={false}
                        label="resources.commands.fields.production"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField
                        source="trade"
                        reference="users"
                        link={false}
                        label="resources.commands.fields.trade"
                    >
                        <TextField source="name"/>
                    </ReferenceField>
                </Datagrid>
            </>
        </List>
    );
};

export default OrderList;

import * as React from 'react';
import {FC, useCallback, useEffect, useState} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    DateField,
    DateInput,
    EditProps,
    FormDataConsumer,
    Labeled,
    NumberField,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    showNotification,
    SimpleFormIterator,
    TextInput,
    Title,
    Toolbar,
    useDataProvider,
    useRecordContext,
    useGetIdentity,
} from 'react-admin';


import {Form, useForm} from 'react-final-form';

import arrayMutators from 'final-form-arrays'

import {Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import {Carrier, Order, Place, Status} from "../../types";
import {AddCircle, Delete, Edit as EditIcon, Save} from "@material-ui/icons";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link, useHistory, useParams,} from "react-router-dom";
import Quantity from '../components/Quantity';
import {NumberFormatCustom, setCalculatedFields} from "../utils";
import {useDispatch} from "react-redux";
import {closeSidebar} from "./actions";
import EPAutocompleteInput1 from "../components/EPAutocompleteInput1";


export const styles: Styles<Theme, any> = {

    customizedButtonGreen: {
        color: '#50A933',
        height: '40px',
        width: '40px',
        marginTop: '10px',

    }
    ,
    customizedButton: {}
    ,
    green: {
        backgroundColor: '#50A933',
    }
    ,
    topbarGreen: {
        backgroundColor: '#50A933',
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
    ,
    title: {
        justifyContent: 'flex-start',
        marginLeft: '10px',
        marginTop: '10px',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    price: {
        marginLeft: '10px',
        marginTop: '10px',
        alignItems: 'end',
        fontStyle: 'bold',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
    },
    subtitle: {
        marginLeft: '30px'
    },
    titleEdit: {
        color: '#FFF',
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    control: {
        width: '50%',
        marginLeft: '48px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topbar: {
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
    },
    greyBg: {
        background: "#FAFAFA",
        border: '1px',
        borderColor: "#F0F0F0",
    },
    root1: {
        top: '5px',
    }
};

const useStyles = makeStyles(styles);


interface OrderTitleProps {
    record?: Order;
}


interface ParamTypes {
    order_id: string
    item_id: string
}

const OrderItemEdit: FC<EditProps> = props => {
    const classes = useStyles(props);
    const params = useParams<ParamTypes>();
    let history = useHistory();


    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(true);
    const [priceCoverage, setpriceCoverage] = useState(0);
    const dataProvider = useDataProvider();
    const [error, setError] = useState();
    const [record, setRecord] = useState({} as any);
    const [recordJSON, setRecordJSON] = useState('');

    const [categories, setCategories] = useState<Record<any, any>[]>([] as Record<any, any>[])
    const [places, setPlaces] = useState<Place[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [customer, setCustomer] = useState({} as any);

    const [selectedCategory, setSelectedCategory] = useState({} as any);
    const [selectedTemplate, setSelectedTemplate] = useState({} as any);
    const [statuses, setStatuses] = useState<Status[]>([])


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closeSidebar());
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(9, 0, 0, 0)
        setInitDate(tomorrow.toISOString());
    }, []);

    const [initDate, setInitDate] = useState('');

    const {identity, loading: identityLoading} = useGetIdentity();


    const getTemplateField = (type: string, name: string, initialValue: any, edit: boolean, getSource) => {
        if (type === 'date') {
            return edit ? <DateInput label={name} fullWidth
                                     source={getSource ? getSource(name) : ''}
                                     initialValue={initialValue}/> : <Labeled label={name}>
                <>  <CalendarTodayIcon style={{fontSize: 12}}/>
                    <DateField fullWidth={true}
                               source={getSource ? getSource(name) : ''} record={record}/></>
            </Labeled>;
        } else if (type === 'text') {
            return edit ? <TextInput label={name} fullWidth
                                     source={getSource ? getSource(name) : ''}
                                     initialValue={initialValue}/> :
                <Labeled label={name}>
                    <div>{record[name]}</div>
                </Labeled>;
        } else if (type === 'number') {
            return edit ? <NumberInput label={name} fullWidth min={0}
                                       source={getSource ? getSource(name) : ''}
                                       initialValue={initialValue}/> :
                <Labeled label={name}>
                    <div>{record[name]} </div>
                </Labeled>;
        } else if (type === 'space') {
            return <div style={{width: '100%'}}></div>
        }

    }

    const NumberFieldCustom = ({source}) => {
        const record = useRecordContext();
        console.log(source);
        return record ?
            <NumberField
                fullWidth={true}
                source={source} record={record} locales={['cs']}
                options={{
                    style: 'currency',
                    currency: 'CZK',
                }}/> :
            null;
    };
    // @ts-ignore
    useEffect(async () => {
            if (params.order_id) {
                let [order2, categories, statuses, places, carriers] = await Promise.all([
                    dataProvider.getOne('commands', {id: params.order_id}),
                    dataProvider
                        .getList("categories", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "name", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList<Status>("statuses", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "name", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList<Place>("places", {
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: "name", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList<Carrier>("carriers", {
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: "name", order: "ASC"},
                            filter: {},
                        }),
                ]);

                // @ts-ignore
                if (order2 && order2.data) {
                    setRecord(order2.data)
                    order2 && order2.data && dataProvider
                        .getList("customers", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "customerId", order: "ASC"},
                            filter: {id: [order2.data.customerId]},
                        })
                        .then(({data}) => {
                            if (data && data.length > 0) {
                                setCustomer(data[0]);
                            }
                        }).catch((error) => {
                            setError(error);
                        });
                }
                // @ts-ignore
                if (categories && categories.data) {
                    setCategories(categories.data);
                    const category = (categories.data || []).find(a => a.id == order2.data.categoryId);
                    if (category) {
                        if (category.templates) {
                            category.templates.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                        }
                        setSelectedCategory(category);
                    }
                }
                // @ts-ignore
                statuses && statuses.data && setStatuses(statuses.data);
                places && places.data && setPlaces(places.data);
                carriers && carriers.data && setCarriers(carriers.data);

                setLoading(false);
            }
        },
        []
    )
    ;


    if (loading) return (
        <CircularProgress
            size={25}
            thickness={2}
        />
    );

    if (!record) return null;


    const CustomToolbar = (props: any) => {

        const form = useForm();
        var formdata = form.getState().values;

        var formDataJson = JSON.stringify(formdata)
        if (!recordJSON) {
            setRecordJSON(formDataJson);
        }

        useEffect(() =>{
            console.log("menim hodnotu");
            console.log(formdata);
            let data;
            let countedPrice = 0;
            
            if (formdata) {
                data = formdata.items;
                
                for (let i = 0; i < data.length; i++) {
                    if (data[i].sellPrice) {
                        countedPrice = countedPrice + (parseFloat(data[i].sellPrice));
                    }
                }
            }
            setpriceCoverage(countedPrice);
        })

        const transform = useCallback((data: any) => {
            return {
                ...data,

            };
        }, []);


        const handleClick = useCallback(() => {
            props.handleSubmit();
        }, [formdata, form]);

        return (
            edit ? <Toolbar {...props} >
                <Box display="flex" justifyContent="space-between" width="100%">

                    {edit && <Button startIcon={<ArrowBackIcon/>} component={Link} to={`/commands/${record.id}`}
                                     variant="contained" key="next" onClick={() => setEdit(!edit)}>
                        Odejít
                    </Button>}

                    {edit && <div><Button startIcon={<Save/>}
                                          color="secondary"
                                          disabled={formDataJson === recordJSON}
                                          type="submit"
                                          style={{marginRight: "20px"}}
                                          onClick={() => {
                                              form.change("saving", "stay");
                                          }}
                                          variant="contained" key="next">
                        Uložit
                    </Button>
                        <Button startIcon={<Save/>}
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    form.change("saving", "full");
                                }}
                                variant="contained" key="next"
                                disabled={formDataJson === recordJSON}>
                            Uložit a ukončit úpravu
                        </Button>
                    </div>
                    }
                </Box>
            </Toolbar> : null
        )
    };


    const submit = values => {
        // React-final-form removes empty values from the form state.
        // To allow users to *delete* values, this must be taken into account
        const newOrder = values
        debugger
        setCalculatedFields(newOrder);

        dataProvider.update("commands", {data: newOrder, id: record.id, previousData: record}).then(({data}) => {
            dispatch(showNotification("Zakázka byla uložena"));
            if (values.saving && values.saving === 'full') {
                history.push(`/commands/${record.id}`);
            } else {
                setRecordJSON('');
            }
        })
            .catch((error) => {
                console.warn(error);

            });
    };
//    const title = `${record.order_name} ${record.orderNumber}`;
    const title = `${record.orderNumber}`;

    function getDeliveryColor(scopedFormData: any) {
        return isDelivered(scopedFormData) ? 'rgb(25 154 237)' : 'rgb(251, 213, 179)';
    }

    function isDelivered(scopedFormData: any) {
        console.info("scopedFormData", scopedFormData);
        if (scopedFormData && scopedFormData.deliveries) {
            let delQuantities = 0;
            for (let i = 0; i < scopedFormData.deliveries.length; i++) {
                if (scopedFormData.deliveries[i]) {
                    delQuantities = delQuantities + (scopedFormData.deliveries[i].quantity || 0);
                }
            }
            if (delQuantities === scopedFormData.quantity) {
                return true;

            }
        }
        return false;

    }

    return (
        <>            <Title title={`Položky zakázky ${record ? title : ''}`}/>
            < Card style={{minHeight: '100%', height: '100%'}}>
                <CardContent>
                    <Form initialValues={record} variant="outlined" onSubmit={submit}
                          validate={values => {
                              // do validation here, and return errors object
                              console.warn(values);
                              return {};
                          }}
                          style={{minHeight: '100%', height: '100%'}}
                          mutators={{
                              ...arrayMutators
                          }}
                          render={formProps => (
                              <form style={{minHeight: '100%', height: '100%'}} onSubmit={formProps.handleSubmit}>
                                  <div className={classes.topbar}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <div className={classes.title}>
                                                <Typography variant="h6" className={classes.title}>
                                                    <span>{record ? `${record.name}` : ''}</span>
                                                </Typography>
                                                <Typography variant="caption" className={classes.subTitle}>
                                                    <span>{customer ? `${customer.name}` : ''} {selectedCategory ? ` - ${selectedCategory.name}` : ''}</span>
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className={classes.price}>
                                                <Typography variant="h6" className={classes.price} style={(priceCoverage === record.sellPrice) ? { color: "green" } : { color: "red" }}>
                                                    <NumberField
                                                        fullWidth={true}
                                                        variant="h6"
                                                        className={classes.price}
                                                        source="sellPrice"
                                                        record={record} locales={['cs']}
                                                        options={{
                                                            style: 'currency',
                                                            currency: 'CZK',
                                                        }} />
                                                </Typography>
                                                <Typography variant="caption" className={classes.subTitle}>
                                                    <span>Prodejní cena celkem</span>
                                                </Typography>    
                                            </div>
                                        </Grid>
                                    </Grid>
                                  </div>
                                  <Grid container spacing={1} style={{minHeight: '70vh', width: '100%'}}>
                                      <ArrayInput source="items" label="">
                                          <SimpleFormIterator
                                              addButton={
                                                  <Button startIcon={<AddCircle/>}
                                                          style={{color: "#008feb"}}>
                                                      Přidat položku zakázky
                                                  </Button>
                                              }
                                              removeButton={
                                                  <IconButton color="secondary" aria-label="add an alarm" style={{
                                                      top: '-10px',
                                                      left: '10px',
                                                  }}
                                                  >
                                                      <Delete/>
                                                  </IconButton>
                                              }>
                                              <FormDataConsumer>
                                                  {({getSource, scopedFormData}) => {
                                                      const tepmlateName = scopedFormData && scopedFormData.itemTypeName || -1;
                                                      const template = (selectedCategory.templates || []).find((a: any) => a.name == tepmlateName);
                                                      return (
                                                          <div style={{
                                                              display: 'flex',
                                                              flexWrap: 'wrap',
                                                              flexDirection: 'row',
                                                              width: "100%",

                                                          }}>

                                                              <div style={{
                                                                  width: "200px",
                                                                  paddingRight: "15px",

                                                              }}>
                                                                  <TextInput label="Název" fullWidth
                                                                             validate={required()}
                                                                             source={getSource ? getSource('itemName') : ''}
                                                                             parse={v => v.toUpperCase()}
                                                                             inputProps={{ style: { textTransform: "uppercase" } }}/>
                                                              </div>
                                                              <div style={{
                                                                  width: "300px",
                                                                  paddingRight: "15px",
                                                              }}>

                                                                  <EPAutocompleteInput1
                                                                      disabled={false}
                                                                      label="Typ"
                                                                      className={classes.root1}
                                                                      source={getSource ? getSource('itemTypeName') : ''}
                                                                      items={selectedCategory.templates}/>

                                                              </div>
                                                              <div style={{
                                                                  width: "75px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <NumberInput label="Počet" fullWidth min={1}
                                                                               validate={required()}
                                                                               source={getSource ? getSource('quantity') : ''}/>
                                                              </div>
                                                              <div style={{
                                                                  width: "300px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <ReferenceInput label="Dodavatel"
                                                                                  isRequired
                                                                                  validate={required()}
                                                                                  source={getSource ? getSource('supplierId') : ''}
                                                                                  optionText="name" optionValue="id"
                                                                                  sort={{field: 'name', order: 'ASC'}}
                                                                                  reference="suppliers">
                                                                      <AutocompleteInput
                                                                          fullWidth
                                                                          resettable
                                                                          validate={required()}
                                                                          label="Dodavatel"
                                                                      />
                                                                  </ReferenceInput>

                                                              </div>
                                                              <div style={{
                                                                  width: "200px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <TextInput label="Prodejní cena za položku"
                                                                             fullWidth
                                                                             validate={required()}
                                                                             InputProps={{
                                                                                 inputComponent: NumberFormatCustom
                                                                             }}
                                                                             source={getSource ? getSource('sellPrice') : ''}/>
                                                              </div>
                                                              <div style={{
                                                                  width: "200px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <TextInput label="Nákladová cena za položku"
                                                                             fullWidth
                                                                             validate={required()}
                                                                             InputProps={{
                                                                                 inputComponent: NumberFormatCustom
                                                                             }}
                                                                             source={getSource ? getSource('price') : ''}/>
                                                              </div>
                                                              <div style={{
                                                                  width: "160px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <SelectInput
                                                                      validate={required()}
                                                                      fullWidth
                                                                      label="Stav"
                                                                      source={getSource ? getSource('statusId') : ''}
                                                                      resource="statuses"
                                                                      choices={statuses}
                                                                      optionText="name"
                                                                      optionValue="id"/>
                                                              </div>
                                                              {
                                                                  template && template.fields.map((item: any, i: number) => {
                                                                      return <div style={{
                                                                          width: item.type === 'number' ? "100px" : "150px",
                                                                          paddingRight: "15px",
                                                                      }}>
                                                                          {
                                                                              getTemplateField(item.type, item.name, item.initialValue, true, getSource)
                                                                          }
                                                                      </div>
                                                                  })
                                                              }
                                                              {
                                                                  !template &&
                                                                  <>
                                                                      <div style={{
                                                                          width: "100px",
                                                                          paddingRight: "15px",
                                                                      }}>
                                                                          {getTemplateField('number', 'width',
                                                                              null, true, getSource)}
                                                                      </div>
                                                                      <div style={{
                                                                          width: "100px",
                                                                          paddingRight: "15px",
                                                                      }}>
                                                                          {getTemplateField('number', 'height',
                                                                              null, true, getSource)}

                                                                      </div>
                                                                      <div style={{
                                                                          width: "150px",
                                                                          paddingRight: "15px",
                                                                      }}>
                                                                          {getTemplateField('text', 'material',
                                                                              null, true, getSource)}

                                                                      </div>
                                                                  </>
                                                              }
                                                              <div style={{
                                                                  width: "200px",
                                                                  paddingRight: "15px",
                                                              }}>
                                                                  <TextInput label="Číslo přijaté faktury" fullWidth
                                                                             source={getSource ? getSource('itemNumber') : ''}/>
                                                              </div>
                                                              <Grid container style={{
                                                                  width: '100%',
                                                                  minHeight: '100px',
                                                                  background: getDeliveryColor(scopedFormData),
                                                                  borderRadius: '20px'
                                                              }}>
                                                                  <ArrayInput
                                                                      source={getSource ? getSource('deliveries') : ''}
                                                                      label={<Typography style={{
                                                                          position: 'absolute',
                                                                          top: '-20px',
                                                                          left: '20px',
                                                                          textDecoration: 'bold',
                                                                          color: '#FFF'
                                                                      }} variant="h5">Závozy</Typography>}>
                                                                      <SimpleFormIterator addButton={
                                                                          <Button startIcon={<AddCircle/>}
                                                                                  style={isDelivered(scopedFormData) ? {color: "#bdbaba"} : {color: "#ff9125"}}
                                                                                  disabled={isDelivered(scopedFormData)}>
                                                                              Přidat závoz
                                                                          </Button>
                                                                      }
                                                                                          removeButton={
                                                                                              <IconButton style={{
                                                                                                  color: "#ff9125",
                                                                                              }}
                                                                                              >
                                                                                                  <Delete/>
                                                                                              </IconButton>
                                                                                          }>

                                                                          <FormDataConsumer>
                                                                              {({
                                                                                    formData, // The whole form data
                                                                                    scopedFormData, // The data for this item of the ArrayInput
                                                                                    getSource, // A function to get the valid source inside an ArrayInput
                                                                                    ...rest
                                                                                }) => {
                                                                                  return (
                                                                                      <Quantity
                                                                                          customer={customer}
                                                                                          initialDateValue={initDate}
                                                                                          carriers={carriers}
                                                                                          places={places}
                                                                                          getSource={getSource}
                                                                                          scopedFormData={scopedFormData}
                                                                                      />
                                                                                  );
                                                                              }}
                                                                          </FormDataConsumer>
                                                                      </SimpleFormIterator>
                                                                  </ArrayInput>
                                                              </Grid>
                                                          </div>
                                                      );
                                                  }}
                                              </FormDataConsumer>
                                          </SimpleFormIterator>
                                      </ArrayInput>
                                  </Grid>


                                  <CustomToolbar props={formProps}></CustomToolbar>
                              </form>
                          )}
                    >
                    </Form>
                </CardContent>
            </Card>
        </>
    );
};


export default OrderItemEdit;

import {useDropzone} from "react-dropzone";
import * as React from "react";
import {useMemo, useState} from "react";
import {useGetIdentity} from "react-admin";
import {EasyFile} from "../../types";
import {addFile} from "../utils";

const tooltipProps: any = {
    textBoxWidth: "200px"
}


const baseStyle: any = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: `"Poppins","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '13px',
    textTransform: 'uppercase',
    // maxWidth: 'calc(100% /7 )',
    // minWidth: 'calc(100% /7 )',
    width: 'calc(14% - 21px)',


    //width: '180px',
    height: '25px',
    padding: '10px 0px 10px 0px',
    backgroundColor: 'rgba(0, 143, 235, 0.25)',
    color: '#fff',
    margin: '15px 0px 15px 0px',
    borderRadius: '5px',
    //borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgb(255,255,255,0)',
};

const activeStyle = {
    backgroundColor: 'rgba(0, 143, 235, 1)',
};

const acceptStyle = {
    backgroundColor: 'rgba(0, 143, 235, 0.8)',
    borderStyle: 'solid',
    transition: 'border .24s ease-in-out, background .5s ease-in-out',
    borderWidth: 2,
};


const rejectStyle = {
    borderColor: '#ff1744'
}

const fileAttached = {
    backgroundColor: 'rgba(0, 143, 235, 1)',

}

export interface StyledDropzone {
    item: string;
    files: any;
    setFiles: (files: any) => void;
    handleOnClick: () => void;
    categoryFiles: EasyFile[];
}

export function StyledDropzone(props: StyledDropzone) {

    const {identity, loading: identityLoading} = useGetIdentity();

    const [show, setShow] = useState(false);
    const [copyModal, setCopyModal] = useState(false);

    const handleClickOpen = () => {
        setCopyModal(true);
    };

    const handleClose = () => {
        setCopyModal(false);
    };


    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop: (acceptedFiles, rejected, event) => {
            addFile(props.item, acceptedFiles, props.files, props.setFiles);
        },

    });


    const style = useMemo(() => ({
        ...baseStyle,
        ...(props.categoryFiles && props.categoryFiles.length > 0 ? fileAttached : {}),
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        props.categoryFiles,
    ]);


    return (
        <div  {...getRootProps({
            style, onClick: event => {
                console.log(event);
                props.handleOnClick();
                event.stopPropagation();
            }
        })} data-tip data-for={props.item} >
            <input name={props.item} id={props.item} {...getInputProps()} />
            <p>{props.item}</p>
        </div>


    );
}

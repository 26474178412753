import * as React from 'react';
import {FC} from 'react';
import DollarIcon from '@material-ui/icons/Receipt';
import {useTranslate} from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
    title?: string
    link?: string
}

const Revenue: FC<Props> = ({value, title, link}) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            backgroundColor="#008feb"
            to={"/commands" + (link ? link : '')}
            icon={DollarIcon}
            title={title}
            subtitle={value}
        />
    );
};

export default Revenue;

import * as React from 'react';
import {Route} from 'react-router-dom';
import Configuration from './configuration/Configuration';
import OrderItemEdit from "./orders/pages/OrderItemEdit";

export default [
    <Route exact path="/configuration" render={() => <Configuration/>}/>,
    <Route exact path="/items/:order_id" render={() => <OrderItemEdit/>}/>,

];

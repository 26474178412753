import { TranslationMessages } from 'react-admin';
import czechMessages from 'ra-language-czech';

const czechMessagesNew = czechMessages;
czechMessagesNew['ra']['action']['export'] = 'Export';
czechMessagesNew['ra']['action']['search'] = 'Vyhledat';
czechMessagesNew['ra']['action']['create_item'] = 'Přidat závoz';

czechMessagesNew['ra']['navigation']['page_rows_per_page'] = 'Počet řádků v tabulce';


const customCzechMessages: TranslationMessages = {
    ...czechMessagesNew,

    new: 'NOVÁ',
    paid: 'ZAPLACENO',
    invoiced: 'VYFAKTUROVÁNO',
    canceled: 'STORNO',
    width:"Šírka",
    height:"Výška",
    material:"Materiál",
    pos: {
        dashboard: {
            late_orders: 'Pozdě',
            sleeping_orders: 'Spící zakázky',
            tommorow_orders: 'Zítra',
            today_orders: 'Dnes',
            in_production_orders: 'Běží'
        },
        configuration: 'Nastavení',
        search: 'Vyhledat',
        language: 'Jazyk',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
    },
    resources: {
        commands: {
            name: 'Zakázka |||| Zakázky',
            amount: '1 zakázka |||| %{smart_count} zakázek',
            title: 'Zakázka %{reference}',
            new: 'NOVÁ',
            paid: 'ZAPLACENO',
            invoiced: 'VYFAKTUROVÁNO',
            canceled: 'STORNO',
            fields: {
                customer: 'Zákazník',
                production: 'Produkční',
                trade: 'Obchod',
                created: 'Datum vytvoření',
                name: 'Název zakázky',
                statusId: 'Stav',
                categoryId: 'Kategorie zakázky',
                items: 'Položky',
                orderNumber: "Číslo zakázky",
                width:"Šírka",
                height:"Výška",
                material:"Materiál",

            },

        },
        deliveries: {
            name: 'Dodací list |||| Dodací listy',
        },
        customers: {
            name: 'Klient |||| Klienti',
            title: 'Klient %{reference}',
            graph: 'Příjem za aktuální rok',
            fields: {
                name: 'Název',
            },
        },
        suppliers: {
            name: 'Dodavatel |||| Dodavatele',
            title: 'Dodavatel %{reference}',
            graph: 'Fakturovano za aktuální rok',
            fields: {
                name: 'Název',
                address: 'Adresa',
                city: 'Mesto',
                zip: 'PSČ',
            },
        }
    },

};

export default customCzechMessages;

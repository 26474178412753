import * as React from 'react';
import { FC } from 'react';
import Icon from '@material-ui/icons/DateRange';
import { useTranslate } from 'react-admin';
import { stringify } from 'query-string';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const TommorowsOrders: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            backgroundColor="#ffc702"
            to={{
                pathname: '/commands',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    filter: JSON.stringify({ filter: 'tomorrow' }),
                }),
            }}
            icon={Icon}
            title={translate('pos.dashboard.tommorow_orders')}
            subtitle={value}
        />
    );
};

export default TommorowsOrders;

import * as React from 'react';
import {FC, useCallback, useEffect, useState} from 'react';
import {
    ArrayField,
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput, DateTimeInput,
    Edit,
    EditProps,
    FormDataConsumer,
    FunctionField,
    Labeled,
    NumberField,
    NumberInput,
    RichTextField,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useGetIdentity,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {useForm} from 'react-final-form';


import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Fab,
    Grid,
    GridList,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';
import {StyledDropzone} from '../components/StyledDropzone';
import {Order, Status, Task} from "../../types";
import Aside from '../components/Aside';
import {Cancel, Edit as EditIcon} from "@material-ui/icons";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Divider from '@material-ui/core/Divider';
import OrderItemShow from '../components/OrderItemShow';
import EditButton from '../components/EditButton';
import {AttachmentTableDialog} from '../components/AttachmentTableDialog';
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import AddIcon from "@material-ui/icons/Add";
import {DeliveryListTable} from '../components/DeliveryListTable';
import {Link} from "react-router-dom";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {useDispatch} from "react-redux";
import {closeSidebar} from "./actions";
import {addFile, NumberFormatCustom, objToArray} from "../utils";
import {DeliveryDoc} from "../components/DeliveryDoc";
import {PDFViewer} from "@react-pdf/renderer";

export const styles: Styles<Theme, any> = {
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    customizedButtonGreen: {
        color: '#008feb',
        height: '40px',
        width: '40px',
        marginTop: '10px',

    },
    content: {
        maxHeight: '100%', 
        width: '100%', 
    },
    notFitting: {
        background: 'rgb(251, 213, 179, .2)', 
        border: '3px solid rgb(251, 213, 179)',
        borderRadius: '20px',  
        padding: '0px 7px 14px 7px',

    },
    fitting: {
        //background: 'rgb(25, 154, 237, .2)', 
        //border: '3px solid rgb(25, 154, 237)',
        border: '3px solid rgb(25, 154, 237, 0.5)',
        background: 'rgb(3, 3, 3, .05)',
        borderRadius: '20px',  
        padding: '0px 7px 14px 7px'
    },
    customizedButton: {}
    ,
    green: {
        backgroundColor: '#008feb',
    },
    myGrid: {
        backgroundColor: 'white',
        padding: '3px',
        borderRadius: '3px',
        height: '48px',
        maxHeight: '48px',
    },
    topbarGreen: {
        backgroundColor: '#008feb',
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
    ,
    title: {
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'block',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    subtitle: {
        marginLeft: '30px'
    },
    titleEdit: {
        color: '#FFF',
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    control: {
        width: '50%',
        marginLeft: '48px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topbar: {
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
    },
    greyBg: {
        background: "#FAFAFA",
        border: '1px',
        borderColor: "#F0F0F0",
    },
    newItemButton: {
        justifyContent: 'flex-end',
        paddingRight: '10px',
        display: 'flex',
        marginTop: '-30px',
    },
    priceRed: {
        color: 'red',
    },
    priceGreen: {
        color: 'green',
    }
};

const useStyles = makeStyles(styles);


interface OrderTitleProps {
    record?: Order;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        key: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            style={{height: '100%'}}
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <div style={{
                maxWidth: '100%',
                display: 'flex',
            }}>

                {children}
            </div>
        </Typography>
    );
}


const OrderEdit: FC<EditProps> = props => {
        const classes = useStyles(props);
        const [loading, setLoading] = useState(true);
        const [edit, setEdit] = useState(false);
        const [headerCollapsed, setHeaderCollapsed] = useState(false);

        const dataProvider = useDataProvider();
        const [error, setError] = useState();
        const [selectedCategory, setSelectedCategory] = useState({} as any);
        const [isCovered, setIsCovered] = useState(false);
        const [files, setFiles] = useState({} as any);
        const [customers, setCustomers] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [categories, setCategories] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [items, setItems] = useState([] as any);
        const [statuses, setStatuses] = useState<Status[]>([]);
        const [suppliers, setSuppliers] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [traders, setTraders] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [productions, setProductions] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [attachmentModal, setAttachmentModal] = useState(false);
        const [selectedAttachmentType, setSelectedAttachmentType] = useState('');

        const [value, setValue] = React.useState(0);

        const [tasks, setTasks] = React.useState([] as any[]);


        const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue);
        };

        const handleAttachmentModalClickOpen = (item: string) => {
            setSelectedAttachmentType(item);
            setAttachmentModal(true);
        };

        const handleAttachmentModalClose = () => {
            setAttachmentModal(false);
        };


        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(closeSidebar());
        }, []);

        const [filesInit, setFilesInit] = useState(false);

        const {identity, loading: identityLoading} = useGetIdentity();


        const validateOrderCreation = (values: any) => {
            const errors = {} as any;
            if (!values.created) {
                errors.created = ['Created is required'];
            }
            if (!values.name) {
                errors.name = ['Name is required'];
            }
            if (!values.categoryId) {
                errors.categoryId = ['Category  is required'];
            }
            if (!values.customerId) {
                errors.customerId = ['Customer is required'];
            }
            if (!values.production) {
                errors.production = ['Production  is required'];
            }
            if (!values.trade) {
                errors.trade = ['Trade  is required'];
            }
            return errors
        };


        const OrderTitle: FC<OrderTitleProps> = ({record}) => {
            const translate = useTranslate();
            return record ? (
                <span>
            {translate('resources.commands.title', {
                reference: record.orderNumber,
            })}
        </span>
            ) : null;
        };


        const getTemplateField = (getSource: any, templateItem: any) => {
            if (templateItem.type === 'date') {
                return <DateInput label={templateItem.name} fullWidth
                                  source={getSource ? getSource(templateItem.name) : ''}/>;
            } else if (templateItem.type === 'text') {
                return <TextInput label={templateItem.name} fullWidth
                                  source={getSource ? getSource(templateItem.name) : ''}/>;
            } else if (templateItem.type === 'number') {
                return <NumberInput label={templateItem.name} fullWidth
                                    source={getSource ? getSource(templateItem.name) : ''}/>;
            } else if (templateItem.type === 'space') {
                return <div style={{width: '100%'}}></div>
            }
        }

        const traderGridSize = 4;


        // @ts-ignore
        useEffect(async () => {
                let [customers, categories, users, statuses, suppliers, tasks] = await Promise.all([dataProvider
                    .getList("customers", {
                        pagination: {page: 1, perPage: 10000},
                        sort: {field: "id", order: "ASC"},
                        filter: {},
                    }), dataProvider
                    .getList("categories", {
                        pagination: {page: 1, perPage: 100},
                        sort: {field: "id", order: "ASC"},
                        filter: {},
                    }),

                    dataProvider
                        .getList("users", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "id", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList<Status>("statuses", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "id", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList("suppliers", {
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: "name", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList("tasks", {
                            pagination: {page: 1, perPage: 1000},
                            sort: {field: "id", order: "ASC"},
                            filter: {order_id: props.id},
                        })
                ]);
                // @ts-ignore
                customers && customers.data && setCustomers(customers.data);
                // @ts-ignore
                categories && categories.data && setCategories(categories.data);
                // @ts-ignore
                users && users.data && setTraders(users.data.filter(a => a.role === 'Trade'));
                // @ts-ignore
                users && users.data && setProductions(users.data.filter(a => a.role === 'Production'));
                // @ts-ignore
                statuses && statuses.data && setStatuses(statuses.data);
                // @ts-ignore
                suppliers && suppliers.data && setSuppliers(suppliers.data);
                // @ts-ignore
                tasks && tasks.data && setTasks(tasks.data);
                setLoading(false);
            },
            []
        )
        ;


        if (loading) return (
            <CircularProgress
                size={25}
                thickness={2}
            />
        );

        if (!customers) return null;

        const getStatusName = (statusId) => {
            const status = (statuses || []).find(a => a.id === statusId);
            if (status) {
                return status.name;
            }
            return "";
        }


        const confirm = async (task, order) => {
            const confirmedTasks = tasks.filter(a => a.ident);
            const taskIndex = tasks.findIndex(a => a.id === task.id);
            const id = `${order.orderNumber}_${confirmedTasks.length + 1}`;
            const newDeliveryList = {...task, ident: id, order_id: order.id};
            const tasksCopy = [...tasks];
            tasksCopy[taskIndex] = newDeliveryList;
            setTasks(tasksCopy);
            var f = new File(["xxxxxxxxxxxxx"], "`${id}.pdf`", {type: "pdf/oc", lastModified: 121212121});
            // var f = new File(["xxxxxxxxxxxxx"], `${id}.pdf`, {type: "pdf/oc", lastModified: 121212121});
            const ret = await addFile("Dodací list", [f], files, setFiles);
            setFilesInit(false);
            dataProvider.update("tasks", {data: newDeliveryList, id: task.id, previousData: task});
        }


        const returnDeliveryItem = (task, order) => {
            const taskIndex = tasks.findIndex(a => a.id === task.id);
            const newDeliveryList = {...task, ident: null, order_id: order.id};
            const tasksCopy = [...tasks];
            tasksCopy[taskIndex] = newDeliveryList;
            setTasks(tasksCopy);
            setFilesInit(false);
            dataProvider.update("tasks", {data: newDeliveryList, id: task.id, previousData: task});
        }


        const CustomToolbar = (props: any) => {

            const form = useForm();
            var formdata = form.getState().values;

            useEffect(() => {
                setIsCovered((props.record) && (props.record.sellPrice === props.record.itemSellPrice))
                if (!filesInit && props.record && props.record.files) {
                    setFilesInit(true);
                    if (props.record.files) {
                        const filesInner = {};
                        for (let i = 0; i < props.record.files.length; i++) {
                            const category = props.record.files[i].category;
                            if (!filesInner[category]) {
                                filesInner[category] = [];
                            }
                            filesInner[category].push({
                                category: props.record.files[i].category,
                                fileName: props.record.files[i].fileName,
                                date: props.record.files[i].date,
                                id: props.record.files[i].id
                            });
                        }
                        setFiles(filesInner)
                    }
                }
                if (props.record && tasks) {
                    for (let i = 0; i < tasks.length; i++) {
                        let customer;
                        if (props.record['customerId']) {
                            customer = customers.find(a => a.id == props.record.customerId);
                        }
                        if (customer) {
                            tasks[i].customerName = customer.name;
                        }
                    }
                }
            }, [props.record, filesInit, tasks]);


            const transform = useCallback((data: any) => {
                const filesC = objToArray(files);
                return {
                    ...data,
                    files: filesC,
                };
            }, [files]);


            const handleClick = useCallback(() => {
                props.handleSubmitWithRedirect('edit');
                setEdit(false);
            }, [formdata, form]);


            return (
                edit ? <Toolbar {...props} >
                    <Box display="flex" justifyContent="space-between" width="100%">
                        {edit && <Button startIcon={<Cancel/>}
                                         variant="contained" key="next" onClick={() => setEdit(!edit)}>
                            Zrušit úpravu zakázky
                        </Button>}
                        <SaveButton
                            handleSubmitWithRedirect={handleClick} transform={transform}/>
                    </Box>
                </Toolbar> : null
            )
        };


        const RichTextFieldCustom = ({source}) => {
            const record = useRecordContext();
            return record ?
                <RichTextField fullWidth={true}
                               source={source} record={record}/> :
                null;
        };

        const DateFieldCustom = ({source}) => {
            const record = useRecordContext();

            return record ?
                <><DateField fullWidth={true} source={source} record={record}/></> :
                null;
        };

        const NumberFieldCustom = ({source}) => {
            const record = useRecordContext();
            return record ?
                <NumberField
                    fullWidth={true}
                    source={source} record={record} locales={['cs']}
                    options={{
                        style: 'currency',
                        currency: 'CZK',
                    }}/> :
                null;
        };
        const NumberFieldCompareCustom = ({source, compare}) => {
            const record = useRecordContext();
            const label = (record[source] == record[compare]) ? <>
                <NumberField
                    fullWidth={true}
                    source={source} record={record} locales={['cs']}
                    className={classes.priceGreen}
                    options={{
                        style: 'currency',
                        currency: 'CZK',
                    }}/>
            </> : <>

                <NumberField
                    fullWidth={true}
                    source={source} record={record} locales={['cs']}
                    className={classes.priceRed}
                    options={{
                        style: 'currency',
                        currency: 'CZK',
                    }}/>
            </>


            return record ?
                label :
                null;
        };

        const TextFieldCustomText = ({source}) => {
            const record = useRecordContext();
            return <span>{record[source]}</span>;
        };

        const ReferenceCustomText = ({source, items, index}) => {

            const record = useRecordContext();
            const selectedItem = items.filter(function (item) {
                return item.id == record[source];
            });
            return <span> {selectedItem[0][index]} </span>
        };

        const TextFieldCustom = ({source}) => {
            const record = useRecordContext();
            return record ? <TextField fullWidth={true}
                                       source={source} record={record}/> : null;
        };

        const ReferenceCustom = ({source, items}) => {
            const record = useRecordContext();
            return record ? <TextFieldItem id={record[source]} items={items}/> : null;
        };


        const TextFieldItem = ({id, items}) => {
            const itemTypeObject = (items || []).find(a => a.id == id);
            return itemTypeObject ? <TextField fullWidth={true} source="name" record={itemTypeObject}/> : null;
        };


        const CustomItemsField = ({}) => {
            const recordParent = useRecordContext();
            return recordParent ? <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div className={classes.newItemButton}>
                    <IconButton className={classes.customizedButtonGreen}
                                size={"small"} aria-label="add" component={Link}
                                to={`/items/${recordParent.id}`}>
                        <EditIcon fontSize="small"/>
                    </IconButton>
                </div>
                <ArrayField source="items" record={recordParent} addLabel={true}>
                    <Datagrid rowClick="expand"
                              expand={<OrderItemShow statuses={statuses} order_id={`${recordParent.id}`}/>}>
                        <TextField source="itemTypeName" label="Položka"/>

                        <TextField source="itemName" label="Název"/>


                        <FunctionField addLabel label="Počet"
                                       render={record => <><NumberField source="quantity" record={record}
                                       />{record.quantity !== record.sumQuantity && <>/
                                           <span style={{color: 'red'}}><NumberField record={record}
                                                                                     source="sumQuantity"/></span></>}</>

                                       }/>

                        <FunctionField addLabel label="Dodavatel"
                                       render={record => <TextFieldItem id={record.supplierId}
                                                                        items={suppliers}/>}/>
                        <NumberField source="sellPrice" label="Prodejní cena celkem"/>

                        <NumberField source="price" label="Nákladová cena celkem"/>


                        <TextField source="number" label="Číslo faktury"/>
                        <FunctionField addLabel label="Stav"
                                       render={record =>
                                           <span> {record && record.statusId ? getStatusName(record.statusId) : ''} </span>}/>
                    </Datagrid>
                </ArrayField></div> : null;
        };


        return (
            <Edit {...props} classes={{main: classes.main}}
                  title={<OrderTitle/>}
                  aside={<Aside/>}
            >
                <SimpleForm variant="outlined"
                            style={{minHeight: '100%', height: '100%'}}
                            toolbar={<CustomToolbar/>}
                            validate={validateOrderCreation}>
                    <div style={{width: '100%'}}>
                        <Grid container spacing={2} classes={{root: `${(isCovered)? classes.fitting:classes.notFitting} ${classes.content}`}}>
                        {!edit ?
                            <>
                                <Grid item xs={10}>
                                        <Typography variant="h6" className={edit ? classes.title : classes.title}>
                                            <TextFieldCustomText source="name"/>
                                        </Typography>
                                    <Typography variant="caption" display="block" gutterBottom
                                        className={classes.subtitle}>
                                        <ReferenceCustom source="statusId" items={statuses}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className={classes.control}>
                                        {!edit &&
                                        <IconButton className={edit ? classes.customizedButton : classes.customizedButtonGreen}
                                                    size={"small"} onClick={() => {
                                            setEdit(!edit)
                                        }}>
                                            <EditIcon fontSize="small"/>
                                        </IconButton>}
                                    </div>
                                </Grid>
                            </>:
                            <>
                                <Grid item xs={6}>
                                    <TextInput
                                        options={{ autoComplete: 'off' }}
                                        resource="commands"
                                        source="name"
                                        fullWidth={true}
                                        parse={v => v.toUpperCase()}
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                    />
                                    <Typography variant="caption" display="block" gutterBottom
                                        className={classes.subtitle}>
                                        <ReferenceCustom source="statusId" items={statuses}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </>
                            }
                            <Grid item xs={6}>
                                {edit ? <AutocompleteInput
                                        source="customerId"
                                        resource="commands"
                                        suggestionLimit={50}
                                        choices={customers}
                                        label="Zákazník"
                                        fullWidth={true}

                                    />
                                    :
                                    <div className={classes.myGrid}>
                                        <Labeled label="resources.commands.fields.customer">
                                            <ReferenceCustom source="customerId" items={customers}/>
                                        </Labeled>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={2}>
                                {edit ? <DateTimeInput
                                    resource="commands"
                                    source="created"
                                    fullWidth={true}
                                /> : <div className={classes.myGrid}>
                                    <Labeled label="resources.commands.fields.created">
                                        <DateFieldCustom source="created"/>
                                    </Labeled>
                                </div>
                                }
                            </Grid>
                            <Grid item xs={2}>

                                {edit ? <AutocompleteInput
                                    source="trade"
                                    label="Obchod"
                                    choices={traders}
                                    fullWidth={true}

                                /> : <div className={classes.myGrid}>
                                    <Labeled label="resources.commands.fields.trade">
                                        <ReferenceCustom source="trade" items={traders}/>
                                    </Labeled>
                                </div>
                                }
                            </Grid>

                            <Grid item xs={2}>
                                {edit ? <AutocompleteInput
                                    isRequired
                                    source="production"
                                    resource="commands"
                                    choices={productions}
                                    fullWidth={true}
                                /> : <div className={classes.myGrid}>
                                    <Labeled label="resources.commands.fields.production">
                                        <ReferenceCustom source="production" items={productions}/>
                                    </Labeled>
                                </div>
                                }
                            </Grid>


                            <Grid item xs={2}>
                                {edit ? <TextInput
                                    source="sellPrice"
                                    label="Prodejní cena celkem"
                                    fullWidth={true}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}

                                /> : <div className={classes.myGrid}>
                                    <Labeled label="Prodejní cena celkem">
                                        <NumberFieldCustom source="sellPrice"/>
                                    </Labeled>
                                </div>

                                }
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.myGrid}>
                                    <Labeled label="Cena dopočítaná z položek">
                                        <NumberFieldCompareCustom source="itemSellPrice" compare="sellPrice"/>
                                    </Labeled>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.myGrid}>
                                    <Labeled label="Nákladová cena celkem">
                                        <NumberFieldCustom source="itemPrice"/>
                                    </Labeled>
                                </div>
                            </Grid>
                            <Grid item xs={6}/>

                            <Grid item xs={6}>
                                <TextInput multiline source="invoiceText" label="Text na fakturu" rows={5} fullWidth={true} inputProps={{
                                    readOnly: (!edit),
                                    disabled: false,
                                    style: {resize: "both"},
                                }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput multiline source="description" label="Popis" rows={5} fullWidth={true} inputProps={{
                                    readOnly: (!edit),
                                    disabled: false,
                                    style: {resize: "both"},
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <AttachmentTableDialog
                                files={files}
                                setFiles={setFiles}
                                open={attachmentModal}
                                handleAttachmentModalClose={handleAttachmentModalClose}
                                selectedCategory={selectedAttachmentType}

                            />
                            <FormDataConsumer>
                                {({formData, ...rest}) => {
                                    const category = (categories || []).find(a => a.id == formData.categoryId);
                                    let attachments = [];
                                    if (category) {
                                        setSelectedCategory(category);
                                        attachments = category.attachments;
                                    }

                                    let filesCopy = {} as any;
                                    if (!filesInit && formData && formData.files) {
                                        setFilesInit(true);
                                        setFiles(formData.files)
                                        filesCopy = formData.files;
                                    } else {
                                        filesCopy = files;
                                    }


                                    return !edit ? <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        textAlign: 'center',

                                    }}>
                                        {
                                            attachments.map((attachment: any, i: number) => {
                                                const acceptedFiles = filesCopy[attachment.name] as [] || [];
                                                return <StyledDropzone item={attachment.name}
                                                                       categoryFiles={acceptedFiles}
                                                                       files={files}
                                                                       setFiles={setFiles}
                                                                       handleOnClick={() => handleAttachmentModalClickOpen(attachment.name)}/>
                                            })
                                        }

                                    </div> : null
                                }}
                            </FormDataConsumer>
                            {!edit && <Separator/>}
                            {!edit &&

                            <div style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1
                            }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Položky"    {...a11yProps(0)}/>
                                    <Tab label="Dodací listy"    {...a11yProps(1)}/>
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <Card style={{
                                        backgroundColor: '#fff',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        width: '100%'
                                    }}>
                                        <CardContent>
                                            <CustomItemsField/>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Card style={{
                                        backgroundColor: '#fff',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        width: '100%'
                                    }}>
                                        <CardContent>
                                            <Grid container spacing={1} style={{width: '100%', backgroundColor: '#fff'}}>
                                                <FormDataConsumer>
                                                    {({formData, ...rest}) => {
                                                        return (
                                                            <div style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                flexDirection: 'row',
                                                                width: "100%",
                                                                justifyContent: 'space-between',
                                                                backgroundColor: '#fff'

                                                            }}>

                                                                <DeliveryListTable
                                                                    order={formData}
                                                                    customer={customers.find(a => a.id == formData.customerId)}
                                                                    tasks={tasks}
                                                                    confirm={confirm}
                                                                    templates={selectedCategory.templates}
                                                                    returnDeliveryItem={returnDeliveryItem}/>

                                                            </div>

                                                        );
                                                    }}
                                                </FormDataConsumer>

                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                            </div>


                            }

                        </Grid>
                    </div>
                </SimpleForm>
            </Edit>
        );
    }
;


const Separator = () =>
    <div style={{width: '10px'}}/>
;

export default OrderEdit;

import * as React from 'react';
import {cloneElement, FC, useEffect} from 'react';

import {
    Datagrid,
    Filter,
    FilterProps,
    List,
    ListProps,
    sanitizeListRestProps,
    SearchInput,
    TextField,
    TopToolbar,
    useListContext,
} from 'react-admin';
import {useDispatch} from "react-redux";
import {closeSidebar} from "../../orders/pages/actions";
import OrderShow from "../../orders/components/OrderShow";


const CustomerFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter variant="standard" {...props}>
        <SearchInput source="q" alwaysOn/>
    </Filter>
);


const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>


            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

        </TopToolbar>
    );
};


const EmptyListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

        </TopToolbar>
    );
};


const CustomerList: FC<ListProps> = props => {

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(closeSidebar());
    }, []);
    return (

        <List
            {...props}
            sort={{field: 'name', order: 'DESC'}}
            perPage={25}
            filters={<CustomerFilter/>}
        >
            <>
                <Datagrid optimized rowClick="edit"
                >
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="address"/>
                    <TextField source="city"/>
                    <TextField source="zip"/>
                    <TextField source="email"/>
                    <TextField source="phone"/>
                </Datagrid>
            </>
        </List>
    );
};

export default CustomerList;

import * as React from 'react';
import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    Edit,
    EditProps,
    Labeled,
    ListContextProvider,
    ReferenceField,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useGetIdentity,
    useList,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import {useForm} from 'react-final-form';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import {Interval, Order} from "../../types";
import {Edit as EditIcon} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {closeSidebar} from "./actions";
import SupplierChart from "./SupplierChart";
import Revenue from "../../orders/components/Revenue";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const stylesDirect = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
    label: {width: '10em', display: 'inline-block'},
    button: {margin: '1em'},
};


export const styles: Styles<Theme, any> = {
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    customizedButtonGreen: {
        color: '#008feb',
        height: '40px',
        width: '40px',
        marginTop: '10px',

    }
    ,
    customizedButton: {}
    ,
    green: {
        backgroundColor: '#008feb',
    },
    myGrid: {
        backgroundColor: 'white',
        padding: '3px',
        borderRadius: '3px',
        height: '48px',
        maxHeight: '48px',
    },
    topbarGreen: {
        backgroundColor: '#008feb',
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
    ,
    title: {
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'block',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    subtitle: {
        marginLeft: '30px'
    },
    titleEdit: {
        color: '#FFF',
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    control: {
        width: '50%',
        marginLeft: '48px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topbar: {
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
    },
    greyBg: {
        background: "#FAFAFA",
        border: '1px',
        borderColor: "#F0F0F0",
    },
    newItemButton: {
        justifyContent: 'flex-end',
        paddingRight: '10px',
        display: 'flex',
        marginTop: '-30px',
    },
    priceRed: {
        color: 'red',
    },
    priceGreen: {
        color: 'green',
    }
};

const useStyles = makeStyles(styles);


interface OrderTitleProps {
    record?: Order;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        key: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

interface ListProps {
    ordersByYear: any;
    value: string;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            style={{height: '100%'}}
            component="div"
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <div style={{
                maxWidth: '100%',
                display: 'flex',
            }}>

                {children}
            </div>
        </Typography>
    );
}


const OrderSimpleList: FC<ListProps> = props => {


    const ordersByYear = props.ordersByYear;
    const value = props.value;
    console.info("value", value);
    const listContext = useList({
        ids: ordersByYear[value] ? ordersByYear[value].map(a => a.order_id) : [],
        data: ordersByYear[value] ? ordersByYear[value] : [],
        loading: false,
        loaded: true,
    });


    return (
        <ListContextProvider value={listContext}>
            <Datagrid>
                <TextField source="orderNumber"/>
                <TextField source="name"/>
                <ReferenceField
                    source="statusId"
                    reference="statuses"
                    link={false}
                    label="resources.commands.fields.statusId"
                >
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="created" showTime/>
                <ReferenceField
                    source="production"
                    reference="users"
                    link={false}
                    label="resources.commands.fields.production"
                >
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField
                    source="trade"
                    reference="users"
                    link={false}
                    label="resources.commands.fields.trade"
                >
                    <TextField source="name"/>
                </ReferenceField>
            </Datagrid>
        </ListContextProvider>
    );

}


const SupplierEdit: FC<EditProps> = props => {


        const intervalActualYear = {
            id: 1,
            name: 'Příjmy za aktuální rok',
            dateFrom: new Date(new Date().getFullYear(), 0, 1),
            dateTo: new Date()
        };

        const intervalPrevYear = {
            id: 2,
            name: 'Příjmy za predchozí rok',
            dateFrom: new Date(new Date().getFullYear() - 1, 0, 1),
            dateTo: new Date(new Date().getFullYear() - 1, 11, 31)
        };

        const fullHistory = (dateFrom, dateTo) => {
            return {
                id: 3,
                name: 'Všechny příjmy', dateFrom, dateTo
            };
        }

        const classes = useStyles(props);
        const [loading, setLoading] = useState(true);
        const [edit, setEdit] = useState(false);
        const [headerCollapsed, setHeaderCollapsed] = useState(false);
        const [selectedInterval, setSelectedInterval] = useState(intervalActualYear);

        const dataProvider = useDataProvider();
        const [error, setError] = useState();
        const [ordersByYear, setOrdersByYear] = useState({} as any);
        const [orders, setOrders] = useState<Order[]>([]);

        const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);

        const [intervals, setIntervals] = useState<Interval[]>([]);
        const [total, setTotal] = useState("0");

        const onIntervalChanged = (orders, interval) => {
            const newOrders = orders.filter(a => (new Date(a.created) <= interval.dateTo && new Date(a.created) >= interval.dateFrom));
            setFilteredOrders(newOrders);
            setTotal(new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'CZK',
            }).format(newOrders.reduce((a, b) => +a + +b.sellPrice, 0)));

        }
        const [traders, setTraders] = useState<Record<any, any>[]>([] as Record<any, any>[]);

        const [value, setValue] = React.useState('');

        const [tabs, setTabs] = React.useState([] as string[]);

        const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(`${newValue}`);
        };


        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(closeSidebar());
        }, []);


        const {identity, loading: identityLoading} = useGetIdentity();


        const validateCustomerCreation = (values: any) => {
            const errors = {} as any;
            if (!values.name) {
                errors.name = ['Name is required'];
            }
            return errors
        };
        // @ts-ignore
        useEffect(async () => {
                let [users, orders] = await Promise.all([

                    dataProvider
                        .getList("users", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "id", order: "ASC"},
                            filter: {},
                        }),
                    dataProvider
                        .getList<Order>("commands", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "customer", order: "ASC"},
                            filter: {supplierIds: [props.id]},
                        })
                ]);
                users && users.data && setTraders(users.data.filter(a => a.role === 'Trade'));

                let newOrders = {};
                let maxYear = 0;
                for (let i = 0; i < orders.data.length; i++) {
                    const orderDate = new Date(orders.data[i].created);
                    const orderYear = orderDate.getFullYear();
                    if (orderYear > maxYear) {
                        maxYear = orderYear;
                    }
                    const yearOrders = newOrders[orderYear] || [];
                    yearOrders.push(orders.data[i]);
                    newOrders = {...newOrders, [orderYear]: yearOrders};
                }
                setOrdersByYear(newOrders);
                setTabs(Object.keys(newOrders));
                setValue(`${maxYear}`);
                const intervalsTmp: Interval[] = [intervalActualYear, intervalPrevYear];
                const min = orders.data.length > 0 ? new Date(orders.data[0].created) : new Date();
                const max = orders.data.length > 0 ? new Date(orders.data[orders.data.length - 1].created) : new Date();
                intervalsTmp.push(fullHistory(min, max));
                setIntervals(intervalsTmp);
                setOrders(orders.data);
                onIntervalChanged(orders.data, selectedInterval);
                setLoading(false);

            },
            []
        );


        if (loading) return (
            <CircularProgress
                size={25}
                thickness={2}
            />
        );


        const CustomToolbar = (props: any) => {

            const form = useForm();
            var formdata = form.getState().values;


            const transform = useCallback((data: any) => {

                return {
                    ...data,
                };
            }, []);


            const handleClick = useCallback(() => {
                props.handleSubmitWithRedirect('list');
            }, [formdata, form]);


            return (
                edit ? <Toolbar {...props} >
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Button startIcon={<ArrowBackIcon/>}
                                variant="contained" color="secondary" key="next" onClick={() => setEdit(!edit)}>
                            Odejít
                        </Button>
                        <SaveButton
                            handleSubmitWithRedirect={handleClick} transform={transform}/>

                    </Box>
                </Toolbar> : null
            )
        };

        const TextFieldCustomText = ({source}) => {
            const record = useRecordContext();
            return <span>{record[source]}</span>;
        }

        const TextFieldCustom = ({source}) => {
            const record = useRecordContext();
            return record ? <TextField fullWidth={true}
                                       source={source} record={record}/> : null;
        };

        const TextFieldItem = ({id, items}) => {
            const itemTypeObject = (items || []).find(a => a.id == id);
            return itemTypeObject ? <TextField fullWidth={true} source="name" record={itemTypeObject}/> : null;
        };

        const ReferenceCustom = ({source, items}) => {
            const record = useRecordContext();
            return record ? <TextFieldItem id={record[source]} items={items}/> : null;
        };


        const CustomerTitle: FC<OrderTitleProps> = ({record}) => {
            const translate = useTranslate();
            return record ? (
                <span>
            {`Úprava klienta ${record.name}`}
        </span>
            ) : null;
        };


        return (
            <Edit {...props} classes={{main: classes.main}}
                  title={<CustomerTitle/>}
            >
                <SimpleForm variant="outlined"
                            style={{minHeight: '100%', height: '100%'}}
                            toolbar={<CustomToolbar/>}
                            validate={validateCustomerCreation}>

                    <div className={edit ? classes.topbar : classes.topbar}>
                        <div className={classes.title}>
                            <Typography variant="h6" className={edit ? classes.title : classes.title}>
                                <TextFieldCustomText source="name"/>
                            </Typography>
                        </div>
                        <div className={classes.control}>

                            {!edit &&
                            <IconButton className={edit ? classes.customizedButton : classes.customizedButtonGreen}
                                        size={"small"} onClick={() => {
                                setEdit(!edit)
                            }}>
                                <EditIcon fontSize="small"/>
                            </IconButton>}
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <Grid container spacing={1} style={{width: '100%'}}>
                            <Grid container spacing={1} xs={6}>
                                <Grid item xs={10}>

                                    <div className={classes.title}>
                                        <div className={classes.subTitle}>
                                            <Typography variant="body1">Základní údaje</Typography></div>
                                    </div>

                                    {edit ? <TextInput
                                        required
                                        resource="customers"
                                        source="name"
                                        fullWidth={true}
                                        label="Název klienta"
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Název klienta">
                                            <TextFieldCustom source="name"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={10}>
                                    {edit ? <AutocompleteInput
                                        required
                                        source="tradeName"
                                        label="Obchod"
                                        choices={traders}
                                        fullWidth={true}

                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Obchod">
                                            <ReferenceCustom source="trade" items={traders}/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={5}>
                                    {edit ? <TextInput
                                        required
                                        label="IČ"
                                        source="companyId"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="IČ">
                                            <TextFieldCustom source="companyId"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={5}>
                                    {edit ? <TextInput
                                        label="DIČ"
                                        source="vatNumber"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="DIČ">
                                            <TextFieldCustom source="vatNumber"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={10}>

                                    <div className={classes.title}>
                                        <div className={classes.subTitle}>
                                            <Typography variant="body1">Sídlo</Typography></div>
                                    </div>

                                    {edit ? <TextInput
                                        resource="customers"
                                        label="Adresa"
                                        source="address"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Adresa">
                                            <TextFieldCustom source="address"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={2}>

                                </Grid>


                                <Grid item xs={2}>
                                    {edit ? <TextInput
                                        resource="customers"
                                        source="zip"
                                        fullWidth={true}
                                        label="PSČ"

                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="PSČ">
                                            <TextFieldCustom source="zip"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={8}>

                                    {edit ? <TextInput
                                        resource="customers"
                                        source="city"
                                        fullWidth={true}
                                        label="Město"
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Město">
                                            <TextFieldCustom source="city"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={10}>
                                    {edit ? <TextInput
                                        label="Email"
                                        resource="customers"
                                        source="email"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Email">
                                            <TextFieldCustom source="email"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>

                                <Grid item xs={10}>
                                    {edit ? <TextInput
                                        label="Telefon"
                                        initialValue="+420"
                                        resource="customers"
                                        source="phone"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Telefon">
                                            <TextFieldCustom source="phone"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                            </Grid>

                            <Grid spacing={1} xs={6}>
                                <Grid item xs={12}>

                                    <div className={classes.title}>
                                        <div className={classes.subTitle}>
                                            <Typography variant="body1">Dodací adresa</Typography></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {edit ? <TextInput
                                        label="Adresa"
                                        source="deliveryAddress"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Adresa">
                                            <TextFieldCustom source="deliveryAddress"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {edit ? <TextInput
                                        label="Kontakt"
                                        source="deliveryContact"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Kontakt">
                                            <TextFieldCustom source="deliveryContact"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {edit ? <TextInput
                                        label="Telefon"
                                        initialValue="+420"
                                        source="deliveryPhone"
                                        fullWidth={true}
                                    /> : <div className={classes.myGrid}>
                                        <Labeled label="Telefon">
                                            <TextFieldCustom source="deliveryPhone"/>
                                        </Labeled>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <CardContent>
                                        {intervals.map((interval) =>
                                            <Button
                                                variant="contained"
                                                style={stylesDirect.button}
                                                color={interval.name === selectedInterval.name ? 'primary' : 'default'}
                                                onClick={() => {
                                                    setSelectedInterval(interval);
                                                    onIntervalChanged(orders, interval);
                                                }}
                                            >
                                                {interval.name}
                                            </Button>
                                        )}
                                    </CardContent>

                                    <div style={stylesDirect.flex}>
                                        <div style={stylesDirect.leftCol}>
                                            <div style={stylesDirect.singleCol}>
                                                <SupplierChart
                                                    supplierId={props.id||'-1'}
                                                    orders={filteredOrders}
                                                    dateFrom={selectedInterval.dateFrom}
                                                    dateTo={selectedInterval.dateTo}
                                                />
                                            </div>
                                        </div>
                                        <div style={stylesDirect.rightCol}>
                                            <div style={stylesDirect.flex}>
                                                <Revenue value={total}
                                                         link={`?filter=%7B"customerId"%3A${props.id}%7D&order=DESC&page=1&perPage=25&sort=created`}/>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>


                        <Fragment>
                            <Tabs
                                value={value}
                                indicatorColor="primary"
                                onChange={handleChange}
                            >
                                {tabs.map(year => (
                                    <Tab
                                        key={year}
                                        label={
                                            `${year} (${ordersByYear[year] ? ordersByYear[year].length : 0})`
                                        }
                                        value={year}
                                    />
                                ))}
                            </Tabs>
                            <Divider/>
                            <div>
                                <OrderSimpleList ordersByYear={ordersByYear} value={`${value}`}/>
                            </div>
                        </Fragment>
                    </div>
                </SimpleForm>
            </Edit>
        );
    }
;


const Separator = () =>
    <div style={{width: '10px'}}/>
;

export default SupplierEdit;

import * as React from 'react';
import {cloneElement, FC, Fragment, useEffect, useState} from 'react';
import AddIcon from '@material-ui/icons/Add';
import ReactPDF, {PDFViewer} from '@react-pdf/renderer';

import {
    BulkExportButton,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FilterProps,
    List,
    ListProps,
    sanitizeListRestProps,
    SearchInput,
    TextField, TextInput,
    TopToolbar,
    useListContext,
} from 'react-admin';
import {Fab, IconButton, Tooltip} from '@material-ui/core';
import {DeliveryDoc} from "../../orders/components/DeliveryDoc";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {closeSidebar} from "../../orders/pages/actions";


const DeliveryFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter variant="standard" {...props}>
        <TextInput label="Místo" source="place" alwaysOn/>
        <DateInput source="date" label="Datum" alwaysOn/>
    </Filter>
);


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
}));


const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>


            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Tooltip title="Nová zákazka" aria-label="add">
                <IconButton aria-label="add">
                    <Fab style={{backgroundColor: '#50A933', color: 'white'}}>
                        <AddIcon/>
                    </Fab>
                </IconButton>
            </Tooltip>
        </TopToolbar>
    );
};


const EmptyListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

        </TopToolbar>
    );
};


const DeliveryList: FC<ListProps> = props => {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closeSidebar());
    }, []);

    const classes = useStyles(props);
    const [open, setOpen] = useState(false);
    const [deliveryList, setDeliveryList] = useState([]);

    const exporter = posts => {
        handleDetail(posts);
    };


    const handleDetail = (deliveryList) => {
        setDeliveryList(deliveryList);
        setOpen(true);
    }


    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkExportButton {...props} />
        </Fragment>
    );

    return (
        <>
            <Dialog
                open={open}
                maxWidth="lg"
                fullWidth
                onClose={() => {
                    setOpen(false)
                }}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogContent style={{width: '100%', height: '100%', overflow: 'hidden', padding: '0px'}}>
                    <PDFViewer style={{width: '100%', height: '80vh'}}>
                        <DeliveryDoc orderTasks={deliveryList}/>
                    </PDFViewer>
                </DialogContent>
            </Dialog>
            <List
                {...props}
                sort={{field: 'created', order: 'DESC'}}
                perPage={25}
                exporter={exporter}
                filters={<DeliveryFilter/>}
                bulkActionButtons={<PostBulkActionButtons/>}
            >
                <Datagrid optimized>
                    <TextField label="Číslo" source="id"/>
                    <TextField label="Místo" source="place"/>
                    <DateField label="Datum" source="date"/>
                </Datagrid>
            </List>
        </>
    );
};

export default DeliveryList;

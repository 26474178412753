import {v4 as uuidv4} from "uuid";
import {EasyFile} from "../types";
import NumberFormat from "react-number-format";

const veVyrobe = 4;
const vyrobeno = 5;
const fakturaci = 7;
const nova = 8;

export function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator=" "
            decimalSeparator=","
            isNumericString
            decimalScale={2}
            allowNegative={false}

        />
    );
}


export function setCalculatedFields(order) {
    if (!order) {
        return;
    }
    let sellPrice = 0;
    let price = 0;

    let sub = [];
    let statuses = [];
    debugger
    if (order.items && order.items.length > 0) {

        for (let i = 0; i < order.items.length; i++) {
            const price1Item = Number(order.items[i]['price']) || 0;
            price += price1Item;
            const price1SellItem = Number(order.items[i]['sellPrice']) || 0;
            sellPrice += price1SellItem;
            if (order.items[i]['supplierId']) {
                sub.push(order.items[i]['supplierId']);
            }
            if (order.items[i]['statusId']) {
                statuses.push(order.items[i]['statusId']);
            }
            if (order.items[i].deliveries) {
                let quantity1 = 0;
                for (let j = 0; j < order.items[i].deliveries.length; j++) {
                    const quantity1item = order.items[i].deliveries[j].quantity || 0;
                    quantity1 += quantity1item;
                }
                order.items[i].sumQuantity = quantity1;
            } else {
                order.items[i].sumQuantity = 0;
            }

        }
    }
    order.itemPrice = price;
    order.itemSellPrice = sellPrice;
    order.supplierIds = sub;
    order.itemStatusIds = statuses;
    setStatus(order);
}

export function setStatus(order) {
    if (order && order.items && order.items.length > 0) {
        debugger
        const oneFakturace = order.items.find(a => a.statusId === fakturaci);
        const allVyrobeno = order.items.find(a => a.statusId !== vyrobeno);
        const allFakturace = order.items.find(a => a.statusId !== fakturaci);

        if (!allFakturace) {
            order.statusId = fakturaci;
            return;
        }
        if (oneFakturace) {
            order.statusId = vyrobeno;
            return;
        }

        if (!allVyrobeno) {
            order.statusId = vyrobeno;
            return;
        }

        order.statusId = veVyrobe;
        return;
    }
    order.statusId = nova;


}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const objToArray = (atts) => {
    const ret = [];
    for (const key of Object.keys(atts)) {
        if (key && atts[key]) {
            for (let i = 0; i < atts[key].length; i++) {
                ret.push(atts[key][i]);
            }

        }
    }
    return ret;
}


export const addFile = async (category, acceptedFiles, files, setFiles) => {
    const easyFiles = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
        const base64 = await toBase64(acceptedFiles[i]);
        easyFiles.push({fileName: acceptedFiles[i].name, content: base64, category});
    }
    const newFiles = (files[category] || []).concat(easyFiles);
    setFiles({...files, [category]: newFiles});
}

export const removeFile = (category, name, files, setFiles) => {
    const itemFiles = files[category];
    if (itemFiles) {
        setFiles({
            ...files, [category]: itemFiles.filter((f) => {
                return !(f.fileName === name)
            })
        });
    }
}

export const formatDate = (dateString) => {
    if (dateString) {
        let dateDate = new Date(dateString);
        return dateDate.getDate() + "/" + (dateDate.getMonth() + 1) + "/" + dateDate.getFullYear() + " " +
            dateDate.getHours() + ":" + dateDate.getMinutes() + ":" + dateDate.getSeconds();
    }
    return '';

}

const downloadBase64 = (content, fileName) => {
    var win = window.open();
    win.document.write("<iframe width='100%' height='100%' src='" + content.replace('message/rfc822', 'message/rfc822 eml') + "' allowfullscreen></iframe>");
}

export const download = (file, dataProvider) => {
    if (file.content) {
        downloadBase64(file.content, file.fileName);
    } else {
        dataProvider.getOne('files', {id: file.id})
            .then(({data}) => {
                downloadBase64(data.content, data.fileName);
            });
    }
}


const downloadLinkHref = (content, fileName) => {
    const linkSource = content;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


export const downloadInner = (file, dataProvider) => {
    if (file.content) {
        downloadLinkHref(file.content, file.fileName);
    } else {
        dataProvider.getOne('files', {id: file.id})
            .then(({data}) => {
                downloadLinkHref(data.content, data.fileName);
            });
    }
}



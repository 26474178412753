import * as React from 'react';
import { FC } from 'react';
import Icon from '@material-ui/icons/Today';
import { useTranslate } from 'react-admin';
import { stringify } from 'query-string';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const TodayOrders: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            backgroundColor="#ff9125"
            to={{
            pathname: '/commands',
            search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({ filter: 'today' }),
            }),
        }}
            icon={Icon}
            title={translate('pos.dashboard.today_orders')}
            subtitle={value}
        />
    );
};

export default TodayOrders;

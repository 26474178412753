import * as React from 'react';
import {FC, createElement} from 'react';
import {Card, Box, Typography, Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';

interface Props {
    icon: FC<any>;
    to: string|object;
    title?: string;
    subtitle?: string | number;
    backgroundColor?: string;
}

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        border: 'none',
 //       borderRadius:'unset',
        borderRadius:'5px',

        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: (props: Props) => ({
        overflow: 'inherit',
        padding: 16,
        background: props.backgroundColor ? 'initial' : `url(${
            theme.palette.type === 'dark' ? cartoucheDark : cartouche
        }) no-repeat`,
        backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#fff',
        },
    }),
    title: {color: '#fff'},
}));

const CardWithIcon: FC<Props> = props => {
    const {icon, title, subtitle, to, children, backgroundColor} = props;
    const classes = useStyles(props);
    return (
        <Card className={classes.card}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {createElement(icon, {fontSize: 'large'})}
                    </Box>
                    <Typography variant="h4" component="h2" className={classes.title}>
                        {subtitle}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                </div>
            </Link>
            {children && <Divider/>}
            {children}
        </Card>
    );
};

export default CardWithIcon;

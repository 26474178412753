import * as React from 'react';
import {FC} from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import {Area, BarChart, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar,} from 'recharts';
import {useTranslate} from 'react-admin';
import {addDays, format, subDays} from 'date-fns';
import {Order} from "../../types";


const days = [];

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

const aggregateOrdersByDay = (orders: Order[], dateFrom: Date, dateTo: Date): { [key: string]: number } => {
    orders.unshift({created: dateFrom, orderNumber:'-1',sellPrice: 0, id: -1, items: []});
    orders.push({created: dateTo, sellPrice: 0, id: -2, orderNumber:'-2',items: []});
    return orders
        .reduce((acc, curr) => {
            const day = format(curr.created, 'YYYY-MM-DD');
            if (!acc[day]) {
                acc[day] = 0;
            }
            acc[day] += curr.sellPrice;
            return acc;
        }, {} as { [key: string]: number });
}

const getRevenuePerDay = (orders: Order[], dateFrom: Date, dateTo: Date): TotalByDay[] => {
    const agg = aggregateOrdersByDay(orders, dateFrom, dateTo);
    const days = [] as TotalByDay[];

    for (const key of Object.keys(agg)) {
        const dateDay = new Date(key);
        
        days.push({date: dateDay.getTime(), total: agg[key]});
    }
    return days;
};

const getRevenuePerMonth = (orders: Order[], dateFrom: Date, dateTo: Date): TotalByMonth[] => {
    
    const agg = aggregateOrdersByDay(orders, dateFrom, dateTo);
    const months = [
        {date: 1, total : 0},
        {date: 2, total : 0},
        {date: 3, total : 0},
        {date: 4, total : 0},
        {date: 5, total : 0},
        {date: 6, total : 0},
        {date: 7, total : 0},
        {date: 8, total : 0},
        {date: 9, total : 0},
        {date: 10, total : 0},
        {date: 11, total : 0},
        {date: 12, total : 0},
    ] as TotalByMonth[];
    for (const key of Object.keys(agg)) {
         const month = new Date(key).getMonth();
         months[month].total += agg[key];

         //days.push({month: dateDay.getTime(), total: agg[key]});
     }
    return months;
};

const yAxisTickFormatter = (number) => {
    return new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0,
    }).format(number);
  }

const CustomerChart: FC<{ orders?: Order[], dateFrom: Date, dateTo: Date }> = ({orders, dateFrom, dateTo}) => {
    const translate = useTranslate();
    if (!orders) return null;

    return (
        <Card>
            <CardContent>
                <div style={{height: 300}}>
                    <ResponsiveContainer width="100%" height={300}>

                        <BarChart
                            width={500}
                            height={300} 
                            margin={{
                                top: 5, right: 0, left: 30, bottom: 5,
                            }}
                            style={{
                                fontSize: '0.8rem',
                                fontFamily: '"Poppins","Roboto", "Helvetica", "Arial", sans-serif',
                            }}
                            data={getRevenuePerMonth(orders, dateFrom, dateTo)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                name="Month"
                            />
                            <YAxis dataKey="total" name="Revenue" unit="" tickFormatter={yAxisTickFormatter}
                                domain={[dataMin => (0 - Math.abs(dataMin)), dataMax => (Math.round((dataMax * 1.2) / 1000) * 1000)]} 
                                />
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={value =>
                                    new Intl.NumberFormat('cs-CZ', {
                                        style: 'currency',
                                        currency: 'CZK',
                                        minimumFractionDigits: 0,
                                    }).format(value as any)
                                }
                                // labelFormatter={(label: any) =>
                                //     dateFormatter(label)
                                // }
                            />
                            <Bar dataKey="total" fill="#008feb" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

interface TotalByDay {
    date: number;
    total: number;
}

interface TotalByMonth {
    date: number;
    total: number;
}

export default CustomerChart;

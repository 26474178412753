import * as React from 'react';
import {FC} from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    DateTimeInput,
    FormDataConsumer,
    NumberInput,
    required, TextInput,
    useTranslate
} from 'react-admin';
import {Styles} from "@material-ui/styles/withStyles";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Carrier, Customer, Place} from "../../types";
import EPAutocompleteInput1 from "./EPAutocompleteInput1";

interface Props {
    getSource: any,
    scopedFormData: any,
    places: Place[],
    carriers: Carrier[],
    initialDateValue: string,
    customer: Customer,

}

export const styles: Styles<Theme, any> = {
    root: {
        "& .MuiFormLabel-root": {
            color: "#ff9125" // or black
        }
    }, root1: {
        "& .MuiFormLabel-root": {
            color: "#ff9125" // or black
        },
        padding: '2px',
    },
    switch: {
        flexDirection: "column-reverse",
    }
};

const useStyles = makeStyles(styles);


const Quantity: FC<Props> = (props) => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const getSource = props.getSource;
    const places = props.places;
    const initialDateValue = props.initialDateValue;
    const carriers = props.carriers;
    const customer = props.customer;
    const scopedFormData = props.scopedFormData;
    debugger
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            width: "100%",
            justifyContent: 'space-around'

        }}>
            <div style={{
                width: "17%"
            }}>
                <DateTimeInput label="Datum závozu" fullWidth validate={required()} className={classes.root}
                               initialValue={initialDateValue}
                               source={getSource ? getSource('date') : ''}/>
            </div>

            {customer.deliveryAddress!=null &&
                <div style={{width: "8%"}}>
                        <BooleanInput label="Ke klientovi" source={getSource ? getSource('toCustomer') : false} className={classes.switch} />
                </div>
            }


            <FormDataConsumer>
                {({formData}) => {
                    const toCustomer = scopedFormData && scopedFormData.toCustomer;
                    const place = places && scopedFormData ? places.find(a => a.name == scopedFormData.placeName) : null;
                    return (
                        <>
                            {!toCustomer && <div style={{
                                width: "10%"
                            }}>

                                <EPAutocompleteInput1
                                    disabled={toCustomer}
                                    label="Místo závozu"
                                    className={classes.root1}
                                    source={getSource ? getSource('placeName') : ''}
                                    items={places}/>

                            </div>}

                            <div style={{
                                width: !toCustomer ? "30%" : "35%"
                            }}>
                                <TextInput label="Adresa" disabled={toCustomer || place}
                                           className={classes.root}
                                           fullWidth
                                           initialValue={toCustomer ? customer.deliveryAddress : place ? place.address : ''}
                                           source={getSource ? getSource('address') : ''}/>
                            </div>

                            <div style={{
                                width: !toCustomer ? "10%" : "15%"
                            }}>
                                <TextInput label="Kontakt" disabled={toCustomer || place}
                                           className={classes.root}
                                           fullWidth
                                           initialValue={toCustomer ? customer.deliveryContact : place ? place.contact : ''}
                                           source={getSource ? getSource('contact') : ''}/>
                            </div>

                        </>
                    )

                }}
            </FormDataConsumer>

            <div style={{
                width: "15%"
            }}>

                <AutocompleteInput
                    fullWidth
                    resettable
                    label="Kdo veze"
                    validate={required()}
                    className={classes.root}
                    source={getSource ? getSource('carrierId') : ''}
                    choices={carriers}
                />
            </div>


            <div style={{
                width: "5%"
            }}>
                <NumberInput label="Počet" fullWidth validate={required()} className={classes.root} min={1}
                             source={getSource ? getSource('quantity') : ''}/>
            </div>

        </div>
    );
};

export default Quantity;

import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {Paper, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    popper: {
        backgroundColor: 'white',
        zIndex: 10,
    },
    option: {
        fontSize: '18px',
    },
}));

export const MUIAutocompleteInput = props => {

        const {meta, className, label, disabled, input, items, ...rest} = props;

        const classes = useStyles(props);

        return (<Autocomplete
                {...input}
                disabled={disabled}
                classes={{
                    option: classes.option,
                    popper: classes.popper,
                }}
                PaperComponent={({children}) => (
                    <Paper style={{background: "white"}}>{children}</Paper>
                )}
                autoHighlight
                freeSolo
                className={className}
                getOptionLabel={option => option.name}
                inputValue={input.value}
                onChange={(event, newValue) => {
                    input.onChange(newValue && newValue.name ? newValue.name : newValue ? newValue : ''); // the event will carry over because 'text' and 'select' inputs both return the 'event.target.value' without any extra logic for the Field component.
                }}
                options={items}
                renderInput={(params) => <TextField margin="dense" required className={className} label={label}
                                                    {...params} {...input} />}
            />
        )
    }
;

import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {
    ArrayField,
    Datagrid,
    EditProps,
    FunctionField,
    NumberField,
    TextField,
    useDataProvider,
    useGetIdentity,
} from 'react-admin';

import { Status} from "../../types";
import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import CopyButton from "./CopyButton";
import OrderItemShow from './OrderItemShow';


export const styles: Styles<Theme, any> = {

    customizedButtonGreen: {
        color: '#90ee90',
    }
    ,
    customizedButton: {}
    ,
    green: {
        backgroundColor: '#90ee90',
    }
    ,
    topbarGreen: {
        backgroundColor: '#50A933',
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
    ,
    title: {
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'block',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    subtitle: {
        marginLeft: '30px'
    },
    titleEdit: {
        color: '#FFF',
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    control: {
        width: '50%',
        marginLeft: '48px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topbar: {
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
    },
    greyBg: {
        background: "#FAFAFA",
        border: '1px',
        borderColor: "#F0F0F0",
    }
};

const useStyles = makeStyles(styles);


export interface OrderShowCondensedProps extends EditProps {
    record?: any;
    addItem?: (item: any) => void
}


const OrderShowCondensed: FC<OrderShowCondensedProps> = props => {
    const classes = useStyles(props);


    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [statuses, setStatuses] = useState<Status[]>([]);

    const dataProvider = useDataProvider();
    const [error, setError] = useState();

    const [categories, setCategories] = useState<Record<any, any>[]>([] as Record<any, any>[]);
    const [suppliers, setSuppliers] = useState<Record<any, any>[]>([] as Record<any, any>[]);

    const [selectedCategory, setSelectedCategory] = useState({} as any);


    const {identity, loading: identityLoading} = useGetIdentity();

    console.warn("record", props.record);

     // @ts-ignore
    useEffect(async () => {
        let [ statuses] = await Promise.all([
            
            dataProvider
                .getList<Status>("statuses", {
                    pagination: {page: 1, perPage: 100},
                    sort: {field: "id", order: "ASC"},
                    filter: {},
                })
            ]);
        },
        []
    )
    ;
    useEffect(() => {
        
        if (props.record) {
            dataProvider
                .getList("categories", {
                    pagination: {page: 1, perPage: 100},
                    sort: {field: "id", order: "ASC"},
                    filter: {},
                })
                .then(({data}) => {
                    const categories1 = data;
                    setCategories(categories1);
                    const selectedCategory1 = (categories1 || []).find(a => a.id == props.record.categoryId);
                    if (selectedCategory1) {
                        setSelectedCategory(selectedCategory1);
                    }
                    dataProvider
                        .getList("suppliers", {
                            pagination: {page: 1, perPage: 1000},
                            sort: {field: "id", order: "ASC"},
                            filter: {},
                        })
                        .then(({data}) => {
                            setSuppliers(data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            setError(error);
                            setLoading(false);
                        });

                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });


        }
    }, []);


    if (loading) return (
        <CircularProgress
            size={25}
            thickness={2}
        />
    );

    if (!props.record) return null;
    


    const TextFieldItem = ({id, items}) => {
        const itemTypeObject = (items || []).find(a => a.id == id);
        return itemTypeObject ? <TextField fullWidth={true} source="name" record={itemTypeObject}/> : null;
    };

    return (
        <Card style={{minHeight: '100%', height: '100%'}}>
            <CardContent>
                <Grid container spacing={1} style={{minHeight: '80px', width: '100%'}}>
                    <ArrayField source="items" record={props.record} addLabel={true}>
                        <Datagrid isRowSelectable={record => true} 
                            rowClick="expand"
                            expand={<OrderItemShow statuses={statuses} order_id={`${props.record.id}`}/>}>
                        

                            <TextField source="itemTypeName" label="Položka"/>
                            <TextField source="itemName" label="Název"/>
                            <FunctionField addLabel label="Počet"
                                           render={record => <><NumberField source="quantity" record={record}
                                           />{record.quantity !== record.sumQuantity && <>/
                                               <span style={{color: 'red'}}><NumberField record={record}
                                                                                         source="sumQuantity"/></span></>}</>

                                           }/>
                            <FunctionField addLabel label="Dodavatel"
                                           render={record => <TextFieldItem id={record.supplierId}
                                                                            items={suppliers}/>}/>

                            <NumberField source="sellPrice" label="Prodejní cena za položku"/>
                            <NumberField source="price" label="Nákladová cena  za položku"/>

                            <TextField source="number" label="Číslo faktury"/>
                        </Datagrid>
                    </ArrayField>
                </Grid>
            </CardContent>
        </Card>

    );
};


export default OrderShowCondensed;

import * as React from 'react';
import {FC} from 'react';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {useTranslate} from 'react-admin';
import {format} from 'date-fns';
import {Order} from "../../types";


const days = [];

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

const aggregateOrdersByDay = (orders: Order[],  supplierId: string, dateFrom: Date, dateTo: Date): { [key: string]: number } => {
    const flatOrders: Order[] = [];
    flatOrders.unshift({created: dateFrom, orderNumber:'-1',sellPrice: 0, id: -1, items: []});
    for (let i = 0; i < orders.length; i++) {
        if (orders[i].items) {
            for (let j = 0; j < orders[i].items.length; j++) {
                // @ts-ignore
                if (orders[i].items[j].supplierId == supplierId) {
                    flatOrders.push({
                        created: new Date(orders[i].created),
                        sellPrice: orders[i].items[j].sellPrice,
                        id: -2,
                        orderNumber:'-2',
                        items: []
                    });
                }

            }
        }

    }
    flatOrders.push({created: dateTo, sellPrice: 0, id: -2, orderNumber:'-2', items: []});
    return flatOrders
        .reduce((acc, curr) => {
            const day = format(curr.created, 'YYYY-MM-DD');
            if (!acc[day]) {
                acc[day] = 0;
            }
            acc[day] += curr.sellPrice;
            return acc;
        }, {} as { [key: string]: number });
}

const getRevenuePerDay = (orders: Order[], supplierId: string, dateFrom: Date, dateTo: Date): TotalByDay[] => {
    const agg = aggregateOrdersByDay(orders, supplierId, dateFrom, dateTo);
    const days = [] as TotalByDay[];

    for (const key of Object.keys(agg)) {
        const dateDay = new Date(key);
        days.push({date: dateDay.getTime(), total: agg[key]});
    }
    return days;
};

const SupplierChart: FC<{ orders?: Order[], dateFrom: Date, dateTo: Date, supplierId: string }> = ({orders,  dateFrom, dateTo, supplierId}) => {
    const translate = useTranslate();
    if (!orders) return null;

    return (
        <Card>
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart data={getRevenuePerDay(orders, supplierId,  dateFrom, dateTo)}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[dateFrom.getTime(), dateTo.getTime()]}
                                tickFormatter={dateFormatter}
                            />
                            <YAxis dataKey="total" name="Revenue" unit=""/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={value =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'CZK',
                                    }).format(value as any)
                                }
                                labelFormatter={(label: any) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

interface TotalByDay {
    date: number;
    total: number;
}

export default SupplierChart;

import * as React from 'react';
import {Admin, DataProvider, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import useAuthProvider from './authProvider';
import themeReducer from './themeReducer';
import {Layout} from './layout';
import customRoutes from './routes';
import czechMessages from './i18n/cs';

import suppliers from './suppliers';
import customers from './customers';
import deliveries from './deliveries';
import orders from './orders';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import Keycloak from "keycloak-js";
import EULogo from "./sources/eu.jpg";

const keycloakUrl = process.env.REACT_APP_KEYCLOAK;
const keycloakRN = process.env.REACT_APP_KEYCLOAK_RN;


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    return czechMessages;
}, 'cs');

interface AppProps {
    dataProvider: DataProvider;
}

export const keycloakInitConfig = {
    onLoad: 'login-required',
    checkLoginIframe: false,
    bearerOnly: true,
    'auth-server-url': keycloakUrl ? keycloakUrl : ' ',
    'ssl-required': 'none',
    resource: 'eprod-front',
    'public-client': false,
    'enable-cors': true,
    'cors-allowed-methods': 'GET, PUT, POST, HEAD, OPTIONS',
    'cors-allowed-headers': 'Origin, Accept, X-Requested-With, Content-Type, Last-Event-ID',
};


export const keycloak = Keycloak({
    clientId: 'eprod-front',
    realm: keycloakRN ? keycloakRN : ' ',
    url: keycloakUrl ? keycloakUrl : ' ',
})

export const onToken = () => {
    if (keycloak.token && keycloak.refreshToken) {
        localStorage.setItem("token", keycloak.token);
        localStorage.setItem("refresh-token", keycloak.refreshToken);
    }
};

export const onTokenExpired = (eventType: string) => {
    if (eventType === 'onTokenExpired') {
        keycloak
            .updateToken(30)
            .then(() => {
                console.log("successfully get a new token", keycloak.token);
            })
            .catch(() => {
                console.error("failed to refresh token");
            });
    }
};

export const logout = () => {
    keycloak.logout()
}

const initialState = {
    admin: { ui: { sidebarOpen: false, viewVersion: 0 } }
}




const CustomAdmin = ({dataProvider}: AppProps) => {
    const customAuthProvider = useAuthProvider('eprod-front');
    return (
        <Admin
            initialState={initialState}
            title="Easy Production"
            loginPage={false}
            dataProvider={dataProvider}
            authProvider={customAuthProvider}
            customReducers={{theme: themeReducer}}
            customRoutes={customRoutes}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource
                name="commands"
                {...orders}
                options={{label: 'Orders'}}
            />
            <Resource name="customers"
                      {...customers}
                      options={{label: 'Klienti'}}
            />
            <Resource name="suppliers"
                      {...suppliers}
                      options={{label: 'Dodavatele'}}
            />
            <Resource name="users"/>
            <Resource name="categories"/>
            <Resource name="tasks"
                      {...deliveries}
                      options={{label: 'Dodací listy'}}
            />
            <Resource name="statuses"/>
            <Resource name="files"/>
            <Resource name="places"/>


        </Admin>
    );
};



// we have a feature to completely switch off the authorization process through env variable on backend
const App = ({dataProvider}: AppProps) => {
    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            LoadingComponent={<div></div>}
            initOptions={keycloakInitConfig}
            onTokens={onToken}
            onEvent={onTokenExpired}
        >
            <React.Fragment>
                <CustomAdmin dataProvider={dataProvider} />
            </React.Fragment>
            <div style={{
    position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
    padding: 6,
    backgroundColor: '#efefef',
    textAlign: 'right',
  }}><strong style={{display: "none", fontFamily: "Poppins", fontWeight: 'lighter', verticalAlign: 'middle', }}>© ScrumWare 2021 <img src={EULogo} style={{height: '50px'}} ></img></strong></div>
        </ReactKeycloakProvider>
    );
};




export default App;

import OrderIcon from '@material-ui/icons/ListAlt';

import OrderList from './pages/OrderList';
import OrderEdit from './pages/OrderEdit';
import OrderCreate from './pages/OrderCreate';

export default {
    list: OrderList,
    edit: OrderEdit,
    icon: OrderIcon,
    create: OrderCreate,
};

import * as React from 'react';
import {FC} from 'react';
import PropTypes from 'prop-types';
import {Record, useLocale, useTranslate,} from 'react-admin';
import {Box, Grid, Card, CardContent, Step, StepContent, StepLabel, Stepper, Typography,} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import OrderIcon from '@material-ui/icons/AttachMoney';
import AccessTimeIcon from '@material-ui/icons/AccessTime';


const useAsideStyles = makeStyles(theme => ({
    root: {
        marginTop: '10px',
        minWidth: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    historyTitle: {
        marginLeft: '0.2em',
        marginRight: '0.2em',
    },
}));

interface AsideProps {
    record?: Record;
    basePath?: string;
}

const Aside: FC<AsideProps> = ({record, basePath}) => {
    const classes = useAsideStyles();
    console.warn("events", record && record.events);
    const locale = useLocale();
    return (
        <div className={classes.root}>
            {record &&
            <Box m="0em 0em 1em 0em">
                <Card>
                    
                </Card>
            </Box>
            }
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

interface EventListProps {
    record?: Record;
    basePath?: string;
}

const useEventStyles = makeStyles({
    stepper: {
        background: 'none',
        border: 'none',
        marginLeft: '0.3em',
    },
    clamp: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
    },
});

const EventList: FC<EventListProps> = ({record, basePath}) => {
    const translate = useTranslate();
    const classes = useEventStyles();
    const locale = useLocale();
    console.warn("events", record && record.events);
    return (
        <>
            <Stepper orientation="vertical" classes={{root: classes.stepper}}>
                {record && record.events && record.events.map((event: any) => (
                    <Step
                        key={`${event.id}`}
                        expanded
                        active
                        completed
                    >
                        <StepLabel
                            StepIconComponent={() => {
                                return (
                                    <OrderIcon
                                        fontSize="small"
                                        color="disabled"
                                        style={{paddingLeft: 3}}
                                    />
                                );
                            }}

                        >
                            {new Date(event.date).toLocaleString(locale, {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })}
                        </StepLabel>
                        <StepContent>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                className={classes.clamp}
                            >
                                <p> {event.data} </p>
                            </Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </>
    );
};

export default Aside;

import * as React from "react";
import {useEffect, useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {DeliveryDoc} from "./DeliveryDoc";
import {PDFViewer, BlobProvider} from "@react-pdf/renderer";
import {makeStyles} from "@material-ui/core/styles";
import {Order, Task} from "../../types";
import ReactPDF from '@react-pdf/renderer';



export interface Template {
    id: string;
    name: string;
}


export interface DeliveryListTableProps {
    order: Order,
    customer: any,
    tasks: Task [];
    templates: Template [];
    confirm: (task: Task, order: Order) => void;
    returnDeliveryItem: (task: Task, order: Order) => void;
}


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
        backgroundColor:'#fff'
    },
    confirmed: {
        height: '20px',
        background: 'rgba(0, 255, 0, 0.05)',
    },
    unConfirmed: {
        height: '20px',
        background: 'rgba(255, 0, 0, 0.05)'
    },
    cell: {
        padding: 0,
        paddingLeft: '10px',
    },
    thinCell: {
        width: '150px',
    },
    midCell: {
        width: '200px',
    },
    rightAlign: {
        textAlign: 'right',
    }

}));

const getDeliveryPlace = (task) => {
    if (task.placeName!==task.address) {
        return (task.placeName+ ', '+  task.address)
    }
    return (task.placeName)
}

export function DeliveryListTable(props: DeliveryListTableProps) {


    const [open, setOpen] = useState(false);
    const [task, setTask] = useState<Task>();

    const classes = useStyles(props);

    const handleDetail = (task) => {
        setTask(task);
        setOpen(true);
    }


    return (
        <>
            <Dialog
                open={open}
                maxWidth="lg"
                fullWidth
                onClose={() => {
                    setOpen(false)
                }}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogContent style={{width: '100%', height: '100%', overflow: 'hidden', padding: '0px'}}>
                {/* <div>
                    <BlobProvider document={<DeliveryDoc orderTasks={[{task, order: props.order}]} />}>
                    {({ blob, url, loading, error }) => {
                        // Do whatever you need with blob here
                        return <div>There's something going on on the fly</div>;
                    }}
                    </BlobProvider>
                </div> */}
                    
                    <PDFViewer style={{width: '100%', height: '80vw'}}>
                        <DeliveryDoc orderTasks={[{task, order: props.order}]}/>
                    </PDFViewer>
                </DialogContent>
            </Dialog>
            <Card style={{minHeight: '100%', height: '100%', width: '100%'}}>
                <CardContent>
                    <Grid container spacing={1} style={{minHeight: '100%', width: '100%'}}>
                        <Grid item xs={12}>
                            <Table style={{width: '100%'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.thinCell}>
                                            Číslo
                                        </TableCell>
                                        <TableCell className={classes.thinCell}>
                                            Datum
                                        </TableCell>
                                        <TableCell className={classes.thinCell}>
                                            Kdo veze
                                        </TableCell>
                                        <TableCell>
                                            Kam se veze
                                        </TableCell>
                                        <TableCell>
                                            Adresa závozu
                                        </TableCell>
                                        <TableCell>
                                            Kontakt
                                        </TableCell>
                                        <TableCell className={classes.thinCell}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.tasks && props.tasks.map(task => (
                                        <TableRow
                                            className={task.ident ? classes.confirmed : classes.unConfirmed}>
                                            <TableCell className={classes.cell}>
                                                {task.ident}
                                            </TableCell>
                                            <TableCell className={`${classes.cell}`}>
                                                {new Date(task.date).toLocaleString()}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {task.deliveries[0].carrierName}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {task.placeName}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {task.address}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {task.contact}
                                            </TableCell>
                                            <TableCell className={`${classes.cell} ${classes.thinCell} ${classes.rightAlign}`}>
                                                {task.ident ? <>
                                                        <Tooltip title="Stáhnout" aria-label="download">
                                                            <IconButton aria-label="download" onClick={() => {
                                                                handleDetail(task);
                                                            }}>
                                                                <CloudDownloadIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Vrátit" aria-label="download">
                                                            <IconButton aria-label="return" onClick={() => {
                                                                props.returnDeliveryItem(task, props.order);
                                                            }}>
                                                                <CloseIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    : <>
                                                        <Tooltip title="Náhled" aria-label="show">
                                                            <IconButton aria-label="potvrdit" onClick={() => {
                                                                handleDetail(task);
                                                            }}>
                                                                <VisibilityIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Vygenerovat" aria-label="download">
                                                            <IconButton aria-label="potvrdit" onClick={() => {
                                                                props.confirm(task, props.order);
                                                            }}>
                                                                <ImportExportIcon fontSize="small"/>
                                                            </IconButton>
                                                        </Tooltip>

                                                    </>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>

    );
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@material-ui/core";
import {Add} from "@material-ui/icons";

class CopyButton extends Component {


    render() {
        const {record, recordParent, addItem} = this.props;
        return record ? <IconButton size="small" onClick={() => {
                console.warn(record, recordParent);
                addItem && addItem(record);
            }} style={{
                color: "#50A933", height: '30px',
                width: '30px',
            }}>

                <Add fontSize="small"/></IconButton> :
            <span/>;
    }
}

CopyButton.propTypes = {
    record: PropTypes.object,
};


export default CopyButton;

import { NoEncryption } from "@material-ui/icons";

export const darkTheme = {};

// @ts-ignore
export const lightTheme = {
    palette: {
        primary: {
            main: '#4f3cc9',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#efefef',
        },
        type: 'light' as 'light',
        red: '#ff0529',
        orange: '#ff9125',
        yellow: '#ffc702',
        green: '#44bc02',
        blue: '#008feb',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        PrivateTabIndicator: {
            backgroundColor: "#FFF",
        },
        RaSimpleFormIterator: {
            line: {
                background: "#fff",
                margin: "10px",
                padding: "10px",
                borderRadius: "10px",
            },
            index: {
                display: 'none',
            },
            indexContainer: {
                display: 'none', 
            },
        },
        MuiListItem:{
            root: {
                '&$selected:hover': {
                color: "#FFF",
                backgroundColor: 'rgb(0, 143, 235)',
                }
            }

        },
        RaLabeled: {
            value:{
                padding: '0px',
            },
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #4f3cc9',
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                backgroundColor: '#efefef',
                border: 'none',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiAppBar: {
            colorSecondary: {
                color: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 'unset',
                borderTopLeftRadius: 'unset',
                borderTopRightRadius: 'unset',
                backgroundColor: '#fff',
                '&$disabled': {
                    backgroundColor: '#d7d7d7',
                },
                '&::placeholder': {
                    color: "#008feb"
                },
            },
            input:{
                padding: '15px 5px 5px'
            },
        },
        MuiInputBase: {
            root: {
                padding: '2px',
                color: "rgba(0, 0, 0, 0.87)",
                "&$focused": {
                    color: "rgba(0, 0, 0, 0.87)"
                },
                borderRadius: 'unset',
                borderTopLeftRadius: 'unset',
                borderTopRightRadius: 'unset',
                backgroundColor: '#fff',
                '&$disabled': {
                    backgroundColor: '#d7d7d7',
                },
            },
            input: {
                '&::placeholder': {
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                    color: "#008feb",
                    fontWeight: 300,
                    fontSize: '1rem',
                    letterSpacing: '0.15008px',
                },
            },
        },
        MuiAutocomplete : {
            option:{
                "fontFamily": `"Poppins","Roboto", "Helvetica", "Arial", sans-serif`,
                fontSize: '0.75rem',
                fontWeight: '300',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                bottomBorderWidth: 2,
                bottomBorderStyle: 'solid',
                bottomBorderColor: 'rgb(255,255,255,0)',
            },
            '*makeStyles':{
                Option: {
                   
                }
            },
        },

        MuiInputAdornment: {
            root: {
                color: "#008feb",
            }
        },
        MuiSvgIcon: {
            colorDisabled : {
                color: "#008feb",
            }
        },
        RaAutocompleteInput: {
            container: {
                padding: '2px',
                color: 'rgba(0, 0, 0, 0.87)',
            },
            suggestionsContainer: {
                backgroundColor: '#rgba(0, 143, 235, 0.1)'
            },
        },
        RaAutocompleteSuggestionItem : {
            root: {
//                backgroundColor: 'rgba(0, 143, 235, 0.9)',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                bottomBorderWidth: 2,
                bottomBorderStyle: 'solid',
                bottomBorderColor: 'rgb(255,255,255,0)',

            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#efefef',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px dotted rgba(0, 0, 0, .4)',
            },
            "&$hover": {
                color: "#0070b8"
            }
        },
        RaDatagrid: {
            table: {
                backgroundColor: '#efefef',
            },
            headerCell: {
                backgroundColor: '#efefef',
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
        MuiFormLabel: {
            root: {
                padding: '2px',
                zIndex: 1,
                color: "#008feb",
                fontWeight: 200,
                fontSize: '1rem',
                "&$focused": {
                    color: "#0070b8"
                }
            },
            focused: {
                padding: '2px',
                "&$focused": {
                    color: "#0070b8"
                }
            },

        },
        MuiTabs: {
            // general overrides for your material tab component here
            root: {
                width: '100%',
                borderBottomWidth: '10px',
            },
        },
        MuiTab: {
            // general overrides for your material tab component here
            textColorPrimary: {
                '&$selected': {
                    color: "#FFF",
                }
            },
            root: {
                backgroundColor: '#fff',
                '&$selected': {
                    backgroundColor: '#008feb',
                }
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
    typography: {
        "fontFamily": `"Poppins","Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
};

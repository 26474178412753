import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';

import dataProviderFactory from './dataProvider';
import App from './App';


const prepareDataProvider = async () => {

    const dataProvider = await dataProviderFactory(
        process.env.REACT_APP_DATA_PROVIDER || ''
    );
    return { dataProvider };
};

prepareDataProvider().then(({ dataProvider }) => {
    ReactDOM.render(
        <App dataProvider={dataProvider} />,
        document.getElementById('root')
    );
});

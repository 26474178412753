import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    DateInput,
    EditProps,
    FunctionField,
    Labeled,
    NumberField,
    NumberInput,
    TextField,
    TextInput,
    useDataProvider,
    useGetIdentity,
    useRecordContext,
} from 'react-admin';


import {Card, CardContent, CircularProgress, Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Status} from "../../types";


export const styles: Styles<Theme, any> = {

    customizedButtonGreen: {
        color: '#90ee90',
    }
    ,
    customizedButton: {},
    myGrid: {
        backgroundColor: 'white',
        padding: '3px',
        borderRadius: '3px',
        height: '60px',
        maxHeight: '60px',
    },
    green: {
        backgroundColor: '#90ee90',
    }
    ,
    topbarGreen: {
        backgroundColor: '#50A933',
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    }
    ,
    title: {
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'block',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    subtitle: {
        marginLeft: '30px'
    },
    titleEdit: {
        color: '#FFF',
        justifyContent: 'flex-start',
        width: '50%',
        marginLeft: '10px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'left',
        fontStyle: 'bold',
    },
    control: {
        width: '50%',
        marginLeft: '48px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    topbar: {
        minHeight: '64px',
        width: '100%',
        marginLeft: '-24px',
        paddingRight: '48px',
        marginTop: '-24px',
        display: 'flex',
    },
    greyBg: {
        background: "#FAFAFA",
        border: '1px',
        borderColor: "#F0F0F0",
    }
};

const useStyles = makeStyles(styles);


export interface OrderItemShowProps extends EditProps {
    order_id: string;
    record?: any;
    statuses: Status[];
}


const OrderItemShow: FC<OrderItemShowProps> = props => {
    console.log(OrderItemShow);
    console.log(props);

    const classes = useStyles(props);


    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);

    const dataProvider = useDataProvider();
    const [error, setError] = useState();
    const [record, setRecord] = useState({} as any);

    const [order, setOrder] = useState({} as any);

    const [categories, setCategories] = useState<Record<any, any>[]>([] as Record<any, any>[]);

    const [selectedCategory, setSelectedCategory] = useState({} as any);
    const [selectedTemplate, setSelectedTemplate] = useState({} as any);


    const {identity, loading: identityLoading} = useGetIdentity();

    const getTemplateField = (templateItem: any, edit: boolean) => {
        if (templateItem.type === 'date') {
            return edit ? <DateInput label={templateItem.name} fullWidth
                                     source={templateItem.name}/> : <Labeled label={templateItem.name}>
                <>  <CalendarTodayIcon style={{fontSize: 12}}/>
                    <DateField fullWidth={true}
                               source={templateItem.name} record={record}/></>
            </Labeled>;
        } else if (templateItem.type === 'text') {
            return edit ? <TextInput label={templateItem.name} fullWidth
                                     source={templateItem.name}/> :
                <Labeled label={templateItem.name}>
                    <div>{record[templateItem.name]}</div>
                </Labeled>;
        } else if (templateItem.type === 'number') {
            return edit ? <NumberInput label={templateItem.name} fullWidth
                                       source={templateItem.name}/> :
                <Labeled label={templateItem.name}>
                    <div>{record[templateItem.name]} </div>
                </Labeled>;
        } else if (templateItem.type === 'space') {
            return <div style={{width: '100%'}}></div>
        }

    }

    const traderGridSize = identity && identity.role === 'trader' ? 3 : 4;

    useEffect(() => {
        if (props.order_id) {
            dataProvider.getOne('commands', {id: props.order_id})
                .then(({data}) => {
                    const order1 = data;
                    const record1 = props.record ? props.record : {};
                    setOrder(order1);
                    console.warn(record1);
                    setRecord(record1);
                    dataProvider
                        .getList("categories", {
                            pagination: {page: 1, perPage: 100},
                            sort: {field: "id", order: "ASC"},
                            filter: {},
                        })
                        .then(({data}) => {
                            const categories1 = data;
                            setCategories(categories1);
                            const selectedCategory1 = (categories1 || []).find(a => a.id == order1.categoryId);
                            if (selectedCategory1) {
                                setSelectedCategory(selectedCategory1);
                                const tepmlateId = record1 && record1.itemTypeId || -1;
                                const template1 = (selectedCategory1.templates || []).find((a: any) => a.id == tepmlateId);
                                setSelectedTemplate(template1);
                            }
                            setLoading(false);
                        })
                        .catch((error) => {
                            setError(error);
                            setLoading(false);
                        });


                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
    }, []);


    if (loading) return (
        <CircularProgress
            size={25}
            thickness={2}
        />
    );

    if (!record) return null;


    const DateFieldCustom = ({source}) => {
        const record = useRecordContext();

        return record ?
            <> <CalendarTodayIcon style={{fontSize: 12}}/> <DateField fullWidth={true}
                                                                      source={source} record={record}/></> :
            null;
    };

    function getDeliveryColor(scopedFormData: any) {
        return isDelivered(scopedFormData) ? 'rgb(25 154 237)' : 'rgb(251, 213, 179)';
    }  
    
    function isDelivered(scopedFormData: any) {
        if (scopedFormData && scopedFormData.deliveries) {
            let delQuantities = 0;
            for (let i = 0; i < scopedFormData.deliveries.length; i++) {
                if (scopedFormData.deliveries[i]) {
                    delQuantities = delQuantities + (scopedFormData.deliveries[i].quantity || 0);
                }
            }
            if (delQuantities === scopedFormData.quantity) {
                return true;
                
            }
        }
        return false;
        
    }


    const QuantitiesField = ({}) => {
        return record ? <ArrayField source="deliveries" record={record} addLabel={true}>
            <Datagrid isRowSelectable={record => true}>
                <TextField label="Místo závozu" source="placeName"/>
                <TextField label="Adresa" source="address"/>
                <TextField label="Kontakt" source="contact"/>
                <NumberField label="Počet kusů" source="quantity"/>
                <DateField label="Datum závozu" source="date"/>
            </Datagrid>
        </ArrayField> : null;
    };


    return (
        <Card style={{minHeight: '100%', height: '100%', background: `${getDeliveryColor(record)}`, paddingTop: '25px'}}>
            <CardContent>
                <Grid container spacing={2} style={{width: '100%', backgroundColor: '#FFF'}}>
                    {selectedTemplate && selectedTemplate.fields && selectedTemplate.fields.map((item: any, i: number) => {
                        return item.type !== 'space' ? <Grid item xs={2}><div className={classes.myGrid}>
                            {getTemplateField(item, edit)}
                            </div>
                        </Grid>: null
                    })
                    }
                </Grid>
                <QuantitiesField/>
            </CardContent>
        </Card>

    );
};


export default OrderItemShow;

import * as React from 'react';
import { FC } from 'react';
import Warning from '@material-ui/icons/Warning';
import { useTranslate } from 'react-admin';
import { stringify } from 'query-string';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const LateOrders: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            backgroundColor="#ff0529"
            to={{
                pathname: '/commands',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    filter: JSON.stringify({ filter: 'late' }),
                }),
            }}
            icon={Warning}
            title={translate('pos.dashboard.late_orders')}
            subtitle={value}
        />
    );
};

export default LateOrders;

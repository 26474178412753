import * as React from 'react';
import {FC, useCallback, useEffect, useState} from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    Create,
    CreateProps,
    DateInput,
    DateTimeInput,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Title,
    Toolbar,
    useDataProvider,
    useGetIdentity
} from 'react-admin';

import {useForm} from 'react-final-form';


import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import {AddCircle, Delete, ChevronLeft, ChevronRight} from "@material-ui/icons";
import {StyledDropzone} from '../components/StyledDropzone';
import Quantity from '../components/Quantity';
import {AttachmentTableDialog} from '../components/AttachmentTableDialog';
import OrderSearch from "../components/OrderSearch";
import {Order, Status} from "../../types";
import {NumberFormatCustom, objToArray, setCalculatedFields} from "../utils";
import {useDispatch} from "react-redux";
import {closeSidebar} from "./actions";
import { env } from 'process';

export const styles: Styles<Theme, any> = {
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    subTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    green: {
        backgroundColor: '#50A933',
    }


};

const useStyles = makeStyles(styles);

const OrderCreate: FC<CreateProps> = props => {
        const classes = useStyles(props);
        const [loading, setLoading] = useState(true);
        const dataProvider = useDataProvider();
        const [error, setError] = useState();
        const [selectedCategory, setSelectedCategory] = useState({} as any);

        const [files, setFiles] = useState({} as any);
        const [customers, setCustomers] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [statuses, setStatuses] = useState<Status[]>([]);


        const [categories, setCategories] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [traders, setTraders] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [productions, setProductions] = useState<Record<any, any>[]>([] as Record<any, any>[]);
        const [attachmentModal, setAttachmentModal] = useState(false);
        const [selectedAttachmentType, setSelectedAttachmentType] = useState('');

        const {identity, loading: identityLoading} = useGetIdentity();
        const actualDate = new Date().toISOString();


        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(closeSidebar());
        }, []);


        const [orderNumber, setOrderNumber] = useState(' ');


        const validateOrderCreation = (values: any) => {
            const errors = {} as any;
            if (!values.created) {
                errors.created = ['Created is required'];
            }
            if (!values.name) {
                errors.name = ['Name is required'];
            }
            if (!values.categoryId) {
                errors.categoryId = ['Category  is required'];
            }
            if (!values.customerId) {
                errors.customerId = ['Customer is required'];
            }
            if (!values.production) {
                errors.production = ['Production  is required'];
            }
            if (!values.trade) {
                errors.trade = ['Trade  is required'];
            }
            return errors
        };


        const handleAttachmentModalClickOpen = (item: string) => {
            setSelectedAttachmentType(item);
            setAttachmentModal(true);
        };

        const handleAttachmentModalClose = () => {
            setAttachmentModal(false);
        };


        const traderGridSize = 4;

        // @ts-ignore
        useEffect(async () => {
            let [order2, customers, categories, users, statuses] = await Promise.all([
                dataProvider.create<Order>("commands", {data: {"id": "-1"}}),
                dataProvider
                    .getList("customers", {
                        pagination: {page: 1, perPage: 10000},
                        sort: {field: "name", order: "ASC"},
                        filter: {},
                    }), dataProvider
                    .getList("categories", {
                        pagination: {page: 1, perPage: 100},
                        sort: {field: "name", order: "ASC"},
                        filter: {},
                    }),

                dataProvider
                    .getList("users", {
                        pagination: {page: 1, perPage: 100},
                        sort: {field: "name", order: "ASC"},
                        filter: {},
                    }),
                dataProvider
                    .getList<Status>("statuses", {
                        pagination: {page: 1, perPage: 100},
                        sort: {field: "name", order: "ASC"},
                        filter: {},
                    })
            ]);

            // @ts-ignore
            order2 && order2.data && setOrderNumber(order2.data.orderNumber)
            // @ts-ignore
            customers && customers.data && setCustomers(customers.data);
            // @ts-ignore
            categories && categories.data && setCategories(categories.data);
            // @ts-ignore
            users && users.data && setTraders(users.data.filter(a => a.role === 'Trade'));
            // @ts-ignore
            users && users.data && setProductions(users.data.filter(a => a.role === 'Production'));
            // @ts-ignore
            statuses && statuses.data && setStatuses(statuses.data);
            setLoading(false);


        }, []);


        if (loading) return (
            <CircularProgress
                size={25}
                thickness={2}
            />
        );

        if (!customers) return null;


        const CustomToolbar = (props: any) => {
            const form = useForm();
            var formdata = form.getState().values;

            const transform = useCallback(async (data: any) => {
                setCalculatedFields(data);
                const filesC = objToArray(files);
                return {
                    ...data,
                    orderNumber: orderNumber,
                    createdBy: identity ? identity.fullName : 'unknown',
                    files: filesC,
                    events: [{
                        date: data.created,
                        data: `${identity ? identity.fullName : 'unknown'}`

                    }],
                };
            }, [files]);


            const handleClick = useCallback(() => {
                props.handleSubmit();
            }, [formdata, form]);

            return <Toolbar {...props}  >
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <SaveButton style={{backgroundColor: '#4a4a4a', color: '#fff'}} label="Položky zakázky"
                                disabled={props.saving}
                                icon={<ChevronRight/>}
                                handleSubmitWithRedirect={handleClick} transform={transform}/>
                </Box>
            </Toolbar>
        };

        const x = document.getElementById('name');
        return (
            <Create title=" "
                    {...props} >

                <SimpleForm style={{minHeight: '100%', height: '100%'}}
                            toolbar={<CustomToolbar/>}
                            redirect={(basePath, id, data) => `/items/${id}`}
                            validate={validateOrderCreation}>
                    <Title title="Vytvořit zakázku"/>

                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return <div className={classes.title}>
                                <div className={classes.subTitle}>
                                    <Typography variant="body1">Zadaní nové zakázky</Typography></div>
                                <div className={classes.subTitle}><Typography variant="body1">Číslo zakázky</Typography>
                                    <Typography variant="h6">&nbsp; {orderNumber} </Typography></div>
                            </div>
                        }}
                    </FormDataConsumer>
                    <Grid container spacing={1} style={{width: '100%'}}>

                        <Grid item xs={6}>
                            <TextInput
                                options={{ autoComplete: 'off' }}
                                resource="commands"
                                source="name"
                                fullWidth={true}
                                parse={v => v.toUpperCase()}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <AutocompleteInput
                                source="categoryId"
                                resource="commands"
                                choices={categories}
                                fullWidth={true}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutocompleteInput
                                source="customerId"
                                resource="commands"
                                label="Zákazník"
                                suggestionLimit={50}
                                choices={customers}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DateTimeInput
                                resource="commands"
                                source="created"
                                fullWidth={true}
                                initialValue={actualDate}

                            />
                        </Grid>
                        <Grid item xs={3}>

                            <AutocompleteInput
                                source="trade"
                                label="Obchod"
                                choices={traders}
                                fullWidth={true}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteInput
                                source="production"
                                resource="commands"
                                choices={productions}
                                fullWidth={true}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                source="sellPrice"
                                label="Prodejní cena celkem"
                                fullWidth={true}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextInput multiline source="invoiceText" label="Text na fakturu" fullWidth={true}
                                       defaultValue="Dovolujeme si Vám fakturovat za produkci zakázky %name% v rozsahu: "/>
                        </Grid>

                        <Grid item xs={6}>

                        </Grid>

                        <Grid item xs={6}>
                            <TextInput multiline source="description" label="Popis" fullWidth={true}/>
                        </Grid>
                        <Separator/>
                        <AttachmentTableDialog
                            files={files}
                            setFiles={setFiles}
                            open={attachmentModal}
                            handleAttachmentModalClose={handleAttachmentModalClose}
                            selectedCategory={selectedAttachmentType}
                        />
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                const category = (categories || []).find(a => a.id == formData.categoryId);
                                let attachments = [];
                                if (category) {
                                    setSelectedCategory(category);
                                    attachments = category.attachments;
                                }

                                return <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    textAlign: 'center',

                                }}>
                                    {
                                        attachments.map((attachment: any, i: number) => {
                                            const acceptedFiles = files[attachment.name] as [] || [];
                                            return <StyledDropzone item={attachment.name}
                                                                   files={files}
                                                                   setFiles={setFiles}
                                                                   handleOnClick={() => handleAttachmentModalClickOpen(attachment.name)}
                                                                   categoryFiles={acceptedFiles}/>

                                        })
                                    }

                                </div>
                            }}
                        </FormDataConsumer>
                    </Grid>
                </SimpleForm>
            </Create>
        );
    }
;


const Separator = () =>
    <Box pt="1em"/>
;

export default OrderCreate;

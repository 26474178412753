import * as React from "react";
import {useEffect, useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import AttachmentTableDialogAside from "./AttachmentTableDialogAside";
import {EasyFile} from "../../types";
import {download, downloadInner, formatDate, removeFile} from "../utils";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {useDataProvider} from "react-admin";

export interface AttachmentTableProps {
    files: any;
    setFiles: (files: any) => void;
    open: boolean;
    selectedCategory: string;
    handleAttachmentModalClose: () => void;
}

export function AttachmentTableDialog(props: AttachmentTableProps) {


    const [category, setCategory] = useState('');
    const [copyModal, setCopyModal] = useState(false);
    const [innerFiles, setInnerFiles] = useState<EasyFile[]>([]);
    const [categories, setCategories] = useState<string[]>([]);


    useEffect(() => {
        setCategory(props.selectedCategory);
    }, [props.selectedCategory]);


    useEffect(() => {
        if (category && category.length > 0) {
            setInnerFiles(props.files[category]);
        } else {
            let allFiles = [] as any;
            for (const key of Object.keys(props.files)) {
                if (key && props.files[key]) {
                    allFiles = [...allFiles, ...props.files[key]];
                }
            }
            setInnerFiles(allFiles);
        }
        let allAttachments = [] as any;
        for (const key of Object.keys(props.files)) {
            if (key) {
                allAttachments.push(key);
            }
        }
        setCategories(allAttachments);

    }, [props.files, category]);


    const dataProvider = useDataProvider();


    return (
        <Dialog
            open={props.open}
            maxWidth="lg"
            fullWidth
            onClose={props.handleAttachmentModalClose}
        >
            <DialogTitle id="alert-dialog-slide-title">{"Přílohy"}</DialogTitle>
            <DialogContent style={{height: '80vh'}}>

                <Card style={{minHeight: '100%', height: '100%'}}>
                    <CardContent>
                        <Grid container spacing={1} style={{minHeight: '100%', width: '100%'}}>
                            <Grid item xs={12}>
                                <AttachmentTableDialogAside attachments={categories}
                                                            handleChange={(event, value) => {
                                                                setCategory(value);
                                                            }}
                                                            selectedAtt={category}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Table style={{width: '100%'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Název
                                            </TableCell>
                                            <TableCell>
                                                Typ
                                            </TableCell>
                                            <TableCell>
                                                Datum nahrání
                                            </TableCell>
                                            <TableCell>
                                                Nahráno
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {innerFiles && innerFiles.map(file => (
                                            <TableRow onClick={() => {
                                                download(file, dataProvider)
                                            }}>
                                                <TableCell>
                                                    {file.fileName}
                                                </TableCell>
                                                <TableCell>
                                                    {file.category}
                                                </TableCell>
                                                <TableCell>
                                                    {formatDate(file.date)}
                                                </TableCell>
                                                <TableCell>
                                                    {file.uploadedBy}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="delete" onClick={(event) => {
                                                        removeFile(file.category, file.fileName, props.files, props.setFiles);
                                                        event.stopPropagation();
                                                    }}>

                                                        <DeleteIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="download" onClick={(event) => {
                                                        downloadInner(file, dataProvider);
                                                        event.stopPropagation();
                                                    }}>

                                                        <CloudDownloadIcon fontSize="small"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    )
        ;
}

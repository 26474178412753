import * as React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';


export interface AttachmentTableDialogAsideProps {
    attachments: string [];
    handleChange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
    selectedAtt: string;
}

export default function AttachmentTableDialogAside(props: AttachmentTableDialogAsideProps) {
    return (
        <ToggleButtonGroup orientation="horizontal" value={props.selectedAtt} exclusive
                           onChange={props.handleChange}>
            {props.attachments && props.attachments.map(att => (
                <ToggleButton value={att} aria-label="list" style={{width: '100px'}}>
                    {att}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );

}


import * as React from 'react';
import {FC, useCallback, useEffect, useState} from 'react';
import {
    AutocompleteInput,
    Create,
    CreateProps, ReferenceInput, required,
    SaveButton,
    SimpleForm,
    TextInput,
    Title,
    Toolbar,
    useDataProvider,
    useGetIdentity
} from 'react-admin';

import {useForm} from 'react-final-form';


import {Box, CircularProgress, Grid, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Styles} from '@material-ui/styles/withStyles';
import {useDispatch} from "react-redux";
import {closeSidebar} from "./actions";

export const styles: Styles<Theme, any> = {
    title: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    subTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    green: {
        backgroundColor: '#50A933',
    }


};

const useStyles = makeStyles(styles);

const SupplierCreate: FC<CreateProps> = props => {
        const classes = useStyles(props);
        const [loading, setLoading] = useState(true);
        const dataProvider = useDataProvider();
        const [error, setError] = useState();
        const [traders, setTraders] = useState<Record<any, any>[]>([] as Record<any, any>[]);


        const {identity, loading: identityLoading} = useGetIdentity();
        const actualDate = new Date().toISOString();


        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(closeSidebar());
        }, []);


        const validateCustomerCreation = (values: any) => {
            const errors = {} as any;

            if (!values.name) {
                errors.name = ['Name is required'];
            }

            if (!values.tradeName) {
                errors.tradeName = ['Trade  is required'];
            }
            return errors
        };


        // @ts-ignore
        useEffect(async () => {
            let [users] = await Promise.all([
                dataProvider
                    .getList("users", {
                        pagination: {page: 1, perPage: 100},
                        sort: {field: "id", order: "ASC"},
                        filter: {},
                    })
            ]);
            // @ts-ignore
            users && users.data && setTraders(users.data.filter(a => a.role === 'Trade'));
            setLoading(false);


        }, []);


        if (loading) return (
            <CircularProgress
                size={25}
                thickness={2}
            />
        );


        const CustomToolbar = (props: any) => {
            const form = useForm();
            var formdata = form.getState().values;


            const transform = useCallback(async (data: any) => {
                return {
                    ...data,
                    createdBy: identity ? identity.fullName : 'unknown',
                };
            }, []);


            const handleClick = useCallback(() => {
                props.handleSubmitWithRedirect('edit');
            }, [formdata, form]);


            return <Toolbar {...props}  >
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <SaveButton color="primary"
                                handleSubmitWithRedirect={handleClick} transform={transform}/>

                </Box>
            </Toolbar>
        };


        return (
            <Create title=" "
                    {...props} >

                <SimpleForm style={{minHeight: '100%', height: '100%'}}
                            toolbar={<CustomToolbar/>}
                            validate={validateCustomerCreation}>

                    <Title title="Vytvořit nového klienta"/>

                    <Grid container spacing={1} style={{width: '100%'}}>
                        <Grid container spacing={1} xs={6}>
                            <Grid item xs={10}>

                                <div className={classes.title}>
                                    <div className={classes.subTitle}>
                                        <Typography variant="body1">Základní údaje</Typography></div>
                                </div>

                                <TextInput
                                    required
                                    resource="customers"
                                    source="name"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={10}>


                                <AutocompleteInput
                                    required
                                    source="tradeName"
                                    label="Obchod"
                                    choices={traders}
                                    fullWidth={true}

                                />
                            </Grid>

                            <Grid item xs={5}>
                                <TextInput
                                    required
                                    label="IČ"
                                    source="companyId"
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={5}>
                                <TextInput
                                    label="DIČ"
                                    source="vatNumber"
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={10}>

                                <div className={classes.title}>
                                    <div className={classes.subTitle}>
                                        <Typography variant="body1">Sídlo</Typography></div>
                                </div>

                                <TextInput
                                    resource="customers"
                                    label="Adresa"
                                    source="address"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={2}>

                            </Grid>


                            <Grid item xs={2}>
                                <TextInput
                                    resource="customers"
                                    source="zip"
                                    fullWidth={true}
                                    label="PSČ"

                                />
                            </Grid>

                            <Grid item xs={8}>

                                <TextInput
                                    resource="customers"
                                    source="city"
                                    fullWidth={true}
                                    label="Město"
                                />
                            </Grid>

                            <Grid item xs={10}>
                                <TextInput
                                    label="Email"
                                    resource="customers"
                                    source="email"
                                    fullWidth={true}
                                />
                            </Grid>

                            <Grid item xs={10}>
                                <TextInput
                                    label="Telefon"
                                    initialValue="+420"
                                    resource="customers"
                                    source="phone"
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid spacing={1} xs={6}>
                            <Grid item xs={12}>

                                <div className={classes.title}>
                                    <div className={classes.subTitle}>
                                        <Typography variant="body1">Dodací adresa</Typography></div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Adresa"
                                    source="deliveryAddress"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Kontakt"
                                    source="deliveryContact"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="Telefon"
                                    initialValue="+420"
                                    source="deliveryPhone"
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                </SimpleForm>
            </Create>
        )
            ;
    }
;


const Separator = () =>
    <Box pt="1em"/>
;

export default SupplierCreate;

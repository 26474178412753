import React from 'react';
import {Page, Text, View, Document, StyleSheet, Font, Image} from '@react-pdf/renderer';
import logo from './logo-rectangle.png';
import stamp from './stamp.png';

Font.register({
    family: "Poppins",
    src: `${process.env.PUBLIC_URL}/static/media/Poppins-Regular.ttf`,

})

Font.register({
    family: "Poppins",
    src: `${process.env.PUBLIC_URL}/static/media/Poppins-Bold.ttf`,
    fontWeight: "bold"
    
});

Font.register({
    family: "Poppins",
    src: `${process.env.PUBLIC_URL}/static/media/Poppins-Medium.ttf`,
    fontWeight: "medium"
});

Font.register({
    family: "Poppins",
    src: `${process.env.PUBLIC_URL}/static/media/Poppins-Thin.ttf`,
    fontWeight: "thin"
});


const styles = StyleSheet.create({

    tableStyle: {
        display: "table",
        width: "auto"
    },
    tableRowStyle: {
        flexDirection: "row"
    },
    firstTableColHeaderStyle: {
        width: "50%",
        color: '#fff',
        backgroundColor: "#666666"
    },
    tableColHeaderStyle: {
        width: "50%",
        color: '#fff',
        borderLeftStyle: "solid",
        borderLeftColor: "#fff",
        borderLeftWidth: 2,
        backgroundColor: "#666666"
    },
    tableColStyle: {
        width: "50%",
        borderBottomStyle: "solid",
        borderBottomColor: "#666666",
        borderBottomWidth: 1,
    },
    tableCellHeaderStyle: {
        textAlign: "center",
        margin: 4,
        fontSize: 12,
        fontWeight: "bold"
    },
    tableCellStyle: {
        textAlign: "center",
        margin: 5,
        fontSize: 10
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        fontFamily: 'Poppins',
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 35,
    },
    addresses: {
        width: '100%',
        flexDirection: 'row',
        paddingBottom: 10,
    },
    footer: {
        width: '100%',
        flexDirection: 'row',
        paddingBottom: 0,
        paddingTop: 10,
    },
    sectionSupplier: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
        width: '50%',
        flexDirection: 'column',
    },
    section: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
        width: '50%',
        flexDirection: 'column',
        borderLeftStyle: "solid",
        borderLeftColor: "#fff",
        borderLeftWidth: 2,
        borderLeftColor: '#3374c3',
    },
    sectionItem: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    key: {
        fontSize: 10,
        fontWeight: "medium",
    },
    key_center: {
        fontSize: 10,
        fontWeight: "medium",
        textAlign: 'center',
    },
    footerHeading: {
        fontSize: 5,
        fontWeight: "medium",
        textAlign: "center"
    },
    value: {
        fontSize: 10,
    },
    title: {
        fontSize: 35,
        color: '#3374c3',
        fontWeight: "demibold",
        textAlign: "right"

    },
    titleSection: {

        paddingTop: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subTitleSection: {
        width: '50%',
        flexDirection: 'column',
        padding: 0,
    },
    subTitleSection1: {
        width: '50%',
        flexDirection: 'column',
        padding: 0,
    },
    subTitle: {
        paddingBottom: 15,
        paddingTop: '-5',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    image: {
        width: '150px',
    },
    stamp: {
        width: '140px',
    },
    title2Bold: {
        fontSize: 10,
        fontWeight: "medium",
    },
    title2: {
        fontSize: 10,
    },
    
    header: {
        paddingLeft: '10px',
        height: '60px',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        lineHeight: '1.6',
    },
    subHeader: {
        height: '25px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        lineHeight: '1.6',  
    },

});


const createTableHeader = () => {
    return (
        <View style={styles.tableRowStyle} fixed>

            <View style={styles.firstTableColHeaderStyle}>
                <Text style={styles.tableCellHeaderStyle}>Název zboží</Text>
            </View>

            <View style={styles.tableColHeaderStyle}>
                <Text style={styles.tableCellHeaderStyle}>Množství</Text>
            </View>
        </View>
    );
};

const createTableRow = (number, item, quantity) => {
    return (
        <View style={styles.tableRowStyle}>
            <View style={styles.tableColStyle}>
                <Text style={styles.tableCellStyle}>{item ? item.itemName : ''}</Text>
            </View>
            <View style={styles.tableColStyle}>
                <Text style={styles.tableCellStyle}>{quantity}</Text>
            </View>
        </View>
    );
};

const createTableFiller = (rouwCount) => {
    const maxRows = 18;
    let myArray = []
    for(let i = rouwCount; i<=maxRows;i++) {
        myArray.push(createTableRow(i, null, ' '))
    }
    return myArray
}

const getTitle = () => {
    return `DODACÍ LIST`;
}

const getSize = (item) => {
    return item && item.height && item.width ? `${item.height} X ${item.width}` : '';
}


const findCorespondingItem = (order, deliveryId) => {
    if (order.items) {
        for (let i = 0; i < order.items.length; i++) {
            if (order.items[i].deliveries) {
                for (let j = 0; j < order.items[i].deliveries.length; j++) {
                    if (order.items[i].deliveries[j].id === deliveryId) {
                        return order.items[i];
                    }
                }
            }
        }
    }
}

    export const onRenderDocument = ({blob, filename}) => {
        var blobUrl = URL.createObjectURL(blob);
        // var a = document.;

        //saveDocument(blobUrl, filename);
  };
  
  export const saveDocument = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (blob, fileName) {
      a.href = blob;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(blob);
    };
  }());

// Create Document Component
export const DeliveryDoc = ({orderTasks}) => {
    debugger
    return <Document onRender={ (blob) => onRenderDocument(blob, 'myCustomFilename.pdf') }>
        {orderTasks && orderTasks.map(orderTask => (
            <Page size="A4" style={styles.page} wrap={true}>
                <View style={styles.titleSection}>
                    <View style={styles.subTitleSection}>
                        <Image style={styles.image} src={logo}/>
                    </View>
                    <View style={styles.subTitleSection1}>
                        <View>
                            <Text style={styles.title}>{getTitle()}</Text>
                        </View>
                        <View style={styles.subTitle}>
                            <View style={styles.subTitle}>
                                <Text
                                    style={styles.title2Bold}> Číslo zakázky: </Text>

                                <Text
                                    style={styles.title2}> {orderTask.order.orderNumber}</Text>
                            </View>
                            <View style={styles.subTitle}>
                                <Text
                                    style={styles.title2Bold}> Datum: </Text>
                                <Text
                                    style={styles.title2}> {new Date(orderTask.task.date).toLocaleDateString()}</Text>

                            </View>
                        </View>
                    </View>
                </View>


                <View style={styles.addresses}>
                    <View style={styles.sectionSupplier}>
                    <Text style={styles.key}>Dodavatel:</Text>
                        <View style={styles.header}>
                            <Text style={styles.value}> Reklamní studio G s.r.o.</Text>
                            <Text style={styles.value}> Madridská 750/26</Text>
                            <Text style={styles.value}> 101 00 Praha 10</Text>
                        </View>
                        <View style={styles.subHeader}>
                            <Text style={styles.key}>Referenční číslo:</Text>
                            <Text style={styles.key}>Řidič / SPZ:</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.key}>Místo závozu:</Text>
                        <View style={styles.header}>
                            <Text style={styles.value}> {orderTask.task.placeName}</Text>
                            <Text style={styles.value}> {orderTask.task.address}</Text>
                        </View>
                        <View style={styles.subHeader}>
                            <Text style={styles.value}><span style={styles.key}>Kontakt:</span> {orderTask.task.contact}</Text>
                        </View>
                    </View>

                </View>

                <View style={styles.tableStyle}>
                    {createTableHeader()}
                    {orderTask.task && orderTask.task.deliveries && orderTask.task.deliveries.map(quantity => {
                        const item = findCorespondingItem(orderTask.order, quantity.id);
                        return createTableRow(orderTask.order.orderNumber, item, quantity.quantity)
                    })}
                    {createTableFiller(orderTask.task.deliveries ? orderTask.task.deliveries.length+1 : 1)}
                </View>
                <View style={styles.footer}>
                    <View style={styles.sectionSupplier}>
                        <Text style={styles.key_center}>Předal:</Text>
                            <Image style={styles.stamp} src={stamp}/>
                        </View>
                    <View style={styles.section}>
                        <Text style={styles.key_center}>Převzal:</Text>
                    </View>
                </View>

            </Page>))}
    </Document>
};
